import api from '../helpers';

export default {
  processSos: {
    key: 'processSos',
    query: () => {
      return api.post('/api/crm/sos-process', {
        _throwError: true,
      });
    },
  },
  processHubspot: {
    key: 'processHubspot',
    query: () => {
      return api.post('/api/crm/hubspot-process', {
        _throwError: true,
      });
    },
  },
};
