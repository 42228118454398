import queries from '../queries';
import helpers from '../helpers';
import { t } from 'i18next';

const NEW_ACCOUNT_FIELDS = ['firstName', 'lastName', 'email', 'password', 'registrationCode'];
const NEW_ACCOUNT_FIELDS_SERIAL = ['firstName', 'lastName', 'email', 'password', 'serial'];

const register = async (newProfile) => {
  const missing = NEW_ACCOUNT_FIELDS.filter((field) => {
    return !Object.prototype.hasOwnProperty.call(newProfile, field);
  });
  if (missing.length) {
    throw new Error(`${t('api.auth.fieldsAreMissing')}: ${missing.join(', ')}`);
  }

  const { data, error } = await queries.auth.registerCode.query(newProfile);
  if (error) {
    throw error;
  }

  return data;
};

const registerSerial = async (newProfile) => {
  const missing = NEW_ACCOUNT_FIELDS_SERIAL.filter((field) => {
    return !Object.prototype.hasOwnProperty.call(newProfile, field);
  });
  if (missing.length) {
    throw new Error(`${t('api.auth.fieldsAreMissing')}: ${missing.join(', ')}`);
  }

  const { data, error } = await queries.auth.registerSerial.query(newProfile);
  if (error) {
    throw error;
  }

  return data;
};

const login = async (username, password, serial) => {
  const res = await queries.auth.authorize.query(username, password, serial);
  if (res.error?.code === 'B02:009') {
    throw new Error('B02:009');
  }

  if (res.error?.code === 'B13:005') {
    throw new Error('B13:005');
  }

  if (res.error || !res.data || !res.data.access_token) {
    throw new Error(t('api.auth.userNotFound'));
  }

  helpers.setAccessToken(res.data.access_token);
  helpers.setRefreshToken(res.data.refresh_token);

  const { data, error } = await queries.me.query();
  if (error) {
    throw error;
  }

  return [
    data,
    {
      accessTokenExpiresAt: Date.now() + res.data.expires_in * 1000,
      refreshTokenExpiresAt: Date.now() + res.data.refresh_expires_in * 1000,
    },
  ];
};

const loginWithRefreshToken = async () => {
  const [token] = helpers.getRefreshToken();
  if (!token) {
    throw new Error(t('api.auth.missingRefreshToken'));
  }

  const res = await queries.auth.refresh.query(token);
  if (res.error || !res.data || !res.data.access_token) {
    throw new Error(t('api.auth.cannotRefreshToken'));
  }

  helpers.setAccessToken(res.data.access_token);
  helpers.setRefreshToken(res.data.refresh_token);

  return {
    accessTokenExpiresAt: Date.now() + res.data.expires_in * 1000,
    refreshTokenExpiresAt: Date.now() + res.data.refresh_expires_in * 1000,
  };
};

const logout = () => {
  helpers.clearTokens();
};

const integration = async (key) => {
  const res = await queries.auth.authorizeUsingKey.query(key);
  if (res.error?.code === 'B02:009') {
    throw new Error('B02:009');
  }

  if (res.error?.code === 'B13:005') {
    throw new Error('B13:005');
  }

  if (res.error || !res.data || !res.data.access_token) {
    throw new Error(t('api.auth.userNotFound'));
  }

  helpers.setAccessToken(res.data.access_token);
  helpers.setRefreshToken(res.data.refresh_token);

  const { data, error } = await queries.me.query();
  if (error) {
    throw error;
  }

  return [
    data,
    {
      accessTokenExpiresAt: Date.now() + res.data.expires_in * 1000,
      refreshTokenExpiresAt: Date.now() + res.data.refresh_expires_in * 1000,
    },
  ];
};

const requestAccessCode = async (callbackUrl) => {
  const { data, error } = await queries.auth.authorizeAccessCode.query(callbackUrl);
  if (error) {
    throw error;
  }

  return data;
};

export default {
  register,
  registerSerial,
  login,
  logout,
  loginWithRefreshToken,
  integration,
  requestAccessCode,
};
