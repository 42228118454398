import auth from './actions/auth';
import users from './actions/users';
import features from './actions/features';
import leaks from './actions/leaks';
import notifications from './actions/notifications';
import assignments from './actions/assignments';
import images from './actions/images';
import devices from './actions/devices';
import companies from './actions/companies';
import facility from './actions/facility';
import audit from './actions/audit';
import compressors from './actions/compressors';
import nameplates from './actions/nameplates';
import datasheets from './actions/datasheets';
import enpis from './actions/enpis';
import firmware from './queries/firmware';
import routes from './actions/routes';
import floorplans from './actions/floorplans';
import energy from './actions/energy';
import utilproviders from './actions/utilproviders';
import crm from './actions/crm';
import filters from './actions/filters';

export default {
  auth,
  users,
  features,
  leaks,
  notifications,
  assignments,
  images,
  devices,
  companies,
  facility,
  audit,
  compressors,
  nameplates,
  datasheets,
  enpis,
  firmware,
  routes,
  floorplans,
  energy,
  utilproviders,
  crm,
  filters,
};
