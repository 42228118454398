/* eslint-disable react/display-name */
import { LinearProgress } from '@material-ui/core';
import { Fragment, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotAuthGuard, AuthGuard, FeatureGuard } from './components/AuthGuard';
import PermissionGuard from './components/PermissionGuard';

import { FEATURES_LIST } from './constants/features';
import PERMISSIONS from './constants/permissions';

const DashboardLayout = lazy(() => import('src/layouts/DashboardLayout'));
const MainLayout = lazy(() => import('src/layouts/MainLayout'));
const AccountView = lazy(() => import('src/views/account'));
const Companies = lazy(() => import('src/views/companies'));
const CompanySettings = lazy(() => import('src/views/companySettings'));
const LoginView = lazy(() => import('src/views/auth/LoginView'));
const CreateCompanyView = lazy(() => import('src/views/auth/CreateCompanyView'));
const RegisterDeviceView = lazy(() => import('src/views/Device/RegisterDeviceView'));
const DevicesView = lazy(() => import('src/views/Device/DevicesView'));
const RegisterAccountView = lazy(() => import('src/views/Device/RegisterAccountView'));
const DeviceLoginView = lazy(() => import('src/views/Device/DeviceLoginView'));
const ForgotPasswordView = lazy(() => import('src/views/auth/ForgotPasswordView'));
const ResetPasswordView = lazy(() => import('src/views/auth/ResetPasswordView'));
const ActionHandler = lazy(() => import('src/views/auth/ActionHandler'));
const AccessCode = lazy(() => import('src/views/auth/AccessCode'));
const NotFoundView = lazy(() => import('src/views/errors/NotFoundView'));
const MyDashboardView = lazy(() => import('src/views/myDashboard'));
const LeakRecords = lazy(() => import('src/views/LeakRecordsList'));
const LeakRecordDetail = lazy(() => import('src/views/leakRecordDetails'));
const LeakRecordCreate = lazy(() => import('src/views/leakRecordCreate'));
const AccountNotFound = lazy(() => import('src/views/errors/AccountNotFound'));
const ReleaseNotes = lazy(() => import('src/views/ReleaseNotes'));
const AssignedActionRecords = lazy(() => import('src/views/AssignmentsList'));
const CalculateLeak = lazy(() => import('src/views/CalculateLeak/index'));
const Tags = lazy(() => import('src/views/TagsList/index'));
const IntegrationView = lazy(() => import('src/views/integration/index'));

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LinearProgress />}>
    <Switch>
      {routes.map((route, index) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        let Guard = route.guard || Fragment;
        let guardProps = {};
        if (Array.isArray(route.guard)) {
          const [component, props] = Guard;
          Guard = component;
          guardProps = props;
        }

        let CheckPermissionGuard = Fragment;
        let permissionsProps = {};
        if (route.permissions) {
          CheckPermissionGuard = PermissionGuard;
          permissionsProps = route.permissions;
        }

        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={`${route.path}${index}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard {...guardProps}>
                <CheckPermissionGuard {...permissionsProps}>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                </CheckPermissionGuard>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: '/404',
    exact: true,
    layout: DashboardLayout,
    component: NotFoundView,
  },
  {
    path: '/account-not-found',
    exact: true,
    layout: MainLayout,
    component: AccountNotFound,
  },
  {
    path: '/login',
    exact: true,
    layout: MainLayout,
    guard: NotAuthGuard,
    component: LoginView,
  },
  {
    path: '/forgot-password',
    exact: true,
    layout: MainLayout,
    component: ForgotPasswordView,
  },
  {
    path: '/register-device',
    exact: true,
    layout: MainLayout,
    guard: [NotAuthGuard, { authRedirectPath: '/app/devices' }],
    component: RegisterDeviceView,
  },
  {
    path: '/register-account',
    exact: true,
    layout: MainLayout,
    guard: NotAuthGuard,
    component: RegisterAccountView,
  },
  {
    path: '/create-company',
    exact: true,
    layout: MainLayout,
    guard: NotAuthGuard,
    component: CreateCompanyView,
  },
  {
    path: '/device-login',
    exact: true,
    layout: MainLayout,
    guard: NotAuthGuard,
    component: DeviceLoginView,
  },
  {
    path: '/register',
    exact: true,
    layout: MainLayout,
    guard: [NotAuthGuard, { authRedirectPath: '/app/devices' }],
    component: RegisterDeviceView,
  },
  {
    path: '/auth/',
    exact: true,
    layout: MainLayout,
    component: ActionHandler,
  },
  {
    path: '/auth/access-code',
    guard: AuthGuard,
    exact: true,
    component: AccessCode,
  },
  {
    path: '/reset-password',
    exact: true,
    layout: MainLayout,
    component: ResetPasswordView,
  },
  {
    path: '/integration',
    exact: true,
    layout: MainLayout,
    component: IntegrationView,
  },
  {
    path: '/knowledge-base',
    exact: true,
    component: () => {
      window.location.href = 'https://7192871.hs-sites.com/prosaris-university';
      return null;
    },
  },
  {
    path: '/app',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        path: '/app/account',
        exact: true,
        guard: [FeatureGuard, { feature: FEATURES_LIST.LOGOUT }],
        permissions: {
          has: PERMISSIONS.VIEW_OWN_PROFILE,
        },
        component: AccountView,
      },
      {
        path: '/app/companies',
        exact: true,
        guard: [FeatureGuard, { feature: FEATURES_LIST.VIEW_COMPANIES }],
        permissions: {
          hasAny: [PERMISSIONS.VIEW_COMPANIES, PERMISSIONS.VIEW_CHILD_COMPANIES, PERMISSIONS.VIEW_USERS, PERMISSIONS.VIEW_CHILD_USERS],
        },
        component: Companies,
      },
      {
        path: '/app/companies/:companyId/settings',
        exact: true,
        permissions: {
          has: PERMISSIONS.VIEW_COMPANY_SETTINGS,
        },
        component: CompanySettings,
      },
      {
        path: '/app/leak-records',
        exact: true,
        permissions: {
          hasAny: [PERMISSIONS.VIEW_OWN_LEAK_RECORDS, PERMISSIONS.VIEW_LEAK_RECORDS, PERMISSIONS.VIEW_CHILD_LEAK_RECORDS],
        },
        component: LeakRecords,
      },
      {
        path: '/app/devices',
        exact: true,
        component: DevicesView,
      },
      {
        path: '/app/assigned-action-records',
        exact: true,
        guard: [FeatureGuard, { feature: FEATURES_LIST.AAR }],
        permissions: {
          has: PERMISSIONS.VIEW_ASSIGNMENT,
        },
        component: AssignedActionRecords,
      },
      {
        path: '/app/leak-record/:recordDocId/details',
        exact: true,
        permissions: {
          hasAny: [PERMISSIONS.VIEW_OWN_LEAK_RECORDS, PERMISSIONS.VIEW_LEAK_RECORDS, PERMISSIONS.VIEW_CHILD_LEAK_RECORDS],
        },
        component: LeakRecordDetail,
      },
      {
        path: '/app/leak-record/create/:type?',
        exact: true,
        permissions: {
          hasAny: [PERMISSIONS.CREATE_MANUAL_LEAK_RECORD],
        },
        component: LeakRecordCreate,
      },
      {
        path: '/app/my-dashboard',
        exact: true,
        permissions: {
          has: PERMISSIONS.VIEW_REPORT,
          redirectTo: '/app/leak-records',
        },
        component: MyDashboardView,
      },
      {
        path: '/app/release-notes',
        exact: true,
        component: ReleaseNotes,
      },
      {
        path: '/app/calculate-leak/:recordDocId?',
        exact: true,
        permissions: {
          hasAny: [PERMISSIONS.VIEW_OWN_LEAK_RECORDS, PERMISSIONS.VIEW_LEAK_RECORDS, PERMISSIONS.VIEW_CHILD_LEAK_RECORDS],
        },
        component: CalculateLeak,
      },
      {
        path: '/app/tags',
        exact: true,
        permissions: {
          hasAny: [PERMISSIONS.VIEW_OWN_LEAK_RECORDS, PERMISSIONS.VIEW_LEAK_RECORDS, PERMISSIONS.VIEW_CHILD_LEAK_RECORDS],
        },
        component: Tags,
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
  {
    path: '*',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        permissions: {
          has: PERMISSIONS.VIEW_REPORT,
          redirectTo: '/app/leak-records',
        },
        component: () => <Redirect to='/app/my-dashboard' />,
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
];

export default routes;
