export default {
  translation: {
    commonUsedWords: {
      login: 'Log in',
      email: 'Email Address',
      password: 'Password',
      emailIsRequired: 'Email is required',
      mustBeAValidEmail: 'Must be a valid email!',
      deviceSerialNumber: 'Device Serial Number',
      next: 'Next',
      success: 'Success',
      submit: 'Submit',
      firstName: 'First Name',
      firstNameIsRequired: 'First name is required',
      lastName: 'Last Name',
      lastNameIsRequired: 'Last name is required',
      save: 'Save',
      delete: 'Delete',
      undoChanges: 'Undo Changes',
      cancel: 'Cancel',
      loading: 'Loading...',
      yes: 'Yes',
      no: 'No',
      clear: 'Clear',
      confirm: 'Confirm',
      noOption: 'No options',
      openText: 'Open',
      edit: 'Edit',
      logout: 'Logout',
      update: 'Update',
      locked: 'Locked',
      unlocked: 'Unlocked',
      latitude: 'Latitude (LAT)',
      longitude: 'Longitude (LONG)',
      ok: 'Ok',
      okay: 'Okay',
      done: 'Done',
      comingSoon: 'Coming Soon...',
      reset: 'Reset',
      attention: 'Attention',
      note: 'Note',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Mandatory fields are missing',
        userNotFound: 'User not found',
        missingRefreshToken: 'Refresh token is missing',
        cannotRefreshToken: 'Cannot refresh token',
      },
      helpers: {
        error: 'Error',
        invalidHTTPMethod: 'Invalid HTTP method',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'Confirm Email Address',
        confirmEmailIsRequired: 'Confirmed email is required',
        emailNotMatched: 'Email entries do not match',
      },
      confirmPassword: {
        confirmPassword: 'Confirm Password',
        confirmPasswordIsRequired: 'Confirmed password is required',
        newPasswordIsRequired: 'New password is required',
        showPassword: 'Show Password',
        passwordLength: 'Passwords must be at least 6 characters',
        passwordContainSpaces: 'Password must not contain spaces',
        passwordNotMatch: 'Password entries do not match',
        newPassword: 'New Password',
      },
      confirmModal: {
        areYouSure: 'Are you sure?',
      },
      appliedFilters: {
        between: 'Between {{firstValue}} and {{secondValue}}',
        bottom: 'Bottom {{primaryValue}}%',
        top: 'Top {{primaryValue}}%',
        activeFilters: 'Active Filters',
        active: 'active',
        closed: 'closed',
      },
      editTable: {
        editTable: 'Edit Table',
        selectDeselect: 'Select / Deselect Table Values',
        resetToDefault: 'Reset to Default',
      },
      errorBoundary: {
        oops: 'Oops! Something unexpected happened.',
        tryAgain: 'Please try again! if problem persist then please contact your administrator.',
        refresh: 'Refresh',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Sorry no results found. Please try again.',
      },
      permissionGuard: {
        invalidPermission: 'Invalid permission structure, 3 elements in array expected',
      },
      preview: {
        findInView: 'Find in view',
        showDetails: 'Show Details',
        closeEsc: 'Close (Esc)',
        prevLeftArrow: 'Prev (Left arrow)',
        nextRightArrow: 'Next (Right arrow)',
        proceedWithoutSaving: 'Navigate without saving',
        continueEditing: 'Continue editing',
        unsavedChanges: {
          partTwo: 'You have ',
          partThree: 'UNSAVED CHANGES',
          partFour: '. Navigating without saving will discard all the changes. Are you sure?',
        },
      },
      printRecord: {
        title: 'Record',
        date: 'Date:',
        location: 'Location',
        leakDetails: 'Details',
        leakParameters: 'Parameters',
        energyLoss: 'Energy Loss',
        leakSourceEquipment: 'Source Equipment',
        notes: 'Notes',
        leakEventStatus: 'Status',
        requiredAction: 'Required Action',
        leakSourceImage: 'Source Image',
        smartLeakTag: 'Smart Tag',
        lossPerYear: 'Loss ({{currency}}/yr)',
        costOfEnergy: 'Cost of Energy ({{currency}}/kWh)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'Assignment has been deleted',
          deleteAssignmentError: 'Assignment cannot be deleted. Please try again later',
          currentlyAssigned: 'Currently Assigned Action',
          repairedBy: 'Repaired By',
          text: 'You are going to delete this assignment. This action cannot be undone',
        },
        actionSaveDate: 'Last saved',
      },
      visibleColumnsPopper: {
        tableValues: 'Select / Deselect Table Values',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Equal to',
        LESS_THAN: 'Less than',
        GREATER_THAN: 'Greater than',
        LESS_THAN_OR_EQUAL_TO: 'Less than or equal to',
        GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to',
        BETWEEN_A_AND_Y: 'Between',
        TOP_PERCENT: 'Top "x"%',
        BOTTOM_PERCENT: 'Bottom "x"%',
      },
      recordFields: {
        CREATED_AT: 'Created At',
        RECORD_ID: 'Record ID Number',
        DOCUMENT_ID: 'Record ID Number',
        DOLLARS_PER_YEAR: 'Loss',
        GAS_COMPONENT: 'Gas/Component',
        LEAK_TYPE: 'Type Profile',
        DATE_TIME: 'Date/Time',
        DB_PEAK: 'Peak dB',
        COMPANY: 'Company',
        NOTES: 'Notes',
        USER: 'User',
        EQUIPMENT_TAG: 'Equipment/Component Tag',
        FLOW: 'Flow (m³/h)',
        EQUIPMENT: 'Unit/Equipment',
        ROUTE: 'Route',
        ROUTE_LOCATION: 'Route Location',
        PROJECT_SURVEY: 'Audit/Survey Record Ref.',
        OUTAGE_STATUS: 'Outage/Shutdown Required?',
        FACILITY: 'Facility/Site',
        ACTION_STATUS: 'Action Status',
        SERVICE_TYPE: 'Service/Type',
        DB_ACTIVE: 'dB Active',
        REFERENCE_ID: 'Project #/Survey ID',
        AMBIENT_TEMPERATURE: 'Ambient Temperature (°C)',
        LEAK_PRESSURE: 'Pressure (kPag)',
        LEAK_TEMPERATURE: 'Gas Temperature (°C)',
        LEAK_CATEGORY: 'Category',
        DISTANCE: 'Distance (m)',
        GAS_COST: 'Cost of Gas ($/m³)',
        COMPRESSOR_FLOW: 'm³/h per BHP',
        DEVICE_ID: 'Device ID',
        COMPRESSOR_BHP: 'Compressor BHP',
        COMPRESSOR_TYPE: 'Compressor Type',
        MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
        ENERGY_COST: 'Cost of Energy ($/kWh)',
        HOURS: 'Hours Running/Year',
        LOCATION: 'Location',
        LAST_MODIFIED: 'Last Saved Date',
        POWER_LOAD: '% Full Load Power',
        CLIENT: 'Client',
        SERVICE: 'Service',
        CAPACITY: '% Capacity',
        EQUIPMENT_TYPE: 'Equipment/Component Type',
        LEAK_TAG: 'Smart Tag #/Work Ref. #',
        REPAIRED_BY: 'Repaired By',
        ALTITUDE: 'Altitude (m)',
        REQUIRED_ACTION: 'Required Action',
        FREQUENCY_LOW_BOUND: 'Frequency (Low)',
        FREQUENCY_HIGH_BOUND: 'Frequency (High)',
        SENSITIVITY: 'Sensitivity',
        KWH_PER_YEAR: 'kWh/year',
        ASSIGNED_BY: 'Assigned By',
        ASSIGNED_TO: 'Assigned To',
        ASSIGNED_DATE: 'Assignment Date',
        LOSS_PER_YEAR: 'Loss (per year)',
        ASSIGNED: 'Assigned',
        CREATOR_USER_ID: 'User ID',
        CURRENCY: 'Currency',
        COMPLETED_ON: 'Completed On',
        IMAGES: 'Source Images',
        ACTION_STATUS_MODIFIED: 'Action Status Modified',
        TYPE: 'Type',
      },
      electricalRecordFields: {
        REGION: 'Region',
        LOCATION_TYPE: 'Location Type',
        VOLTAGE: 'Voltage',
        PHASE: 'Phase',
        FREQUENCY: 'Frequency',
        ELECTRICAL_EQUIPMENT_TAG: 'Equipment/Component',
        ELECTRICAL_EQUIPMENT_TYPE: 'Equipment/Component Type',
        FAULT_TYPE: 'Fault Type',
        ELECTRICAL_NOTES: 'Notes',
      },
      IMPERIAL_LABELS: {
        FLOW: 'Flow (cfm)',
        AMBIENT_TEMPERATURE: 'Ambient Temperature (°F)',
        LEAK_PRESSURE: 'Pressure (psi)',
        LEAK_TEMPERATURE: 'Gas Temperature (°F)',
        DISTANCE: 'Distance (ft)',
        GAS_COST: 'Cost of Gas ($/ft³)',
        COMPRESSOR_FLOW: 'cfm per BHP',
        ALTITUDE: 'Altitude (ft)',
      },
      userFields: {
        EMAIL: 'Email',
        ROLE: 'Role',
        DEVICE_ID: 'Device ID',
        SUBSCRIPTION_RENEWAL_DATE: 'Subscription Renewal Date',
        DATE_TIME: 'Created',
        ACTIVE: 'Status',
        SOFTWARE_VERSION: 'Software Version',
        FIRMWARE_VERSION: 'Firmware Version',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        COMPANY_SLUG: 'Company',
        COMPANY_NAME: 'Company',
      },
      auditFields: {
        documentId: 'Record ID Number',
        companyName: 'Company Name',
        auditorName: 'Auditor Name',
        auditorEmail: 'Auditor Email',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        comment: 'Comments',
        isLocked: 'Audit Lock',
      },
      deviceFields: {
        DEVICE_ID: 'Device ID',
        ACTIVE: 'Status',
        REGISTERED_ON: 'Activated On',
        EVENT_COUNT: 'Records',
        UNIQUE_COMPANIES: 'Companies',
        LAST_USED_AT: 'Last Used At',
        LAST_USED_BY_NAME: 'Last Used Name',
        LAST_USED_BY_EMAIL: 'Last Used Email',
        LOCATION: 'Last Used Location',
        FIRMWARE_VERSION: 'Firmware Version',
      },
      statsFields: {
        labels: {
          TIMELINE: 'Timeline',
          CRITERIA: 'Criteria',
        },
        timeline: {
          LAST_3_MONTHS: 'Last 3 months',
          LAST_6_MONTHS: 'Last 6 months',
          LAST_12_MONTHS: 'Last 12 months',
          YEAR_TO_DATE: 'Year to date',
          ALL_TIME: 'All time',
        },
        criteria: {
          LOSS: 'Loss',
          FLOW: 'Flow',
          KWH: 'kWh',
        },
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: '# Leaks Found',
          NO_OF_LEAKS_REPAIRED: '# Leaks Repaired',
          PERCENT_LEAKS_REPAIRED: '% Leaks Repaired',
          VOL_FLOW_FOUND: 'Volume Flow Found (m³/h)',
          VOL_FLOW_REPAIRED: 'Volume Flow Repaired (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '% Vol. Flow Repaired',
          VALUE_FOUND: 'Value Found',
          VALUE_REPAIRED: 'Value Repaired',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energy saving opportunity (Found) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Energy saving opportunity % (Found v calculated or measured)',
          ENERGY_SAVING_REALIZED_FIXED: 'Energy saving realized (Fixed) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: 'Energy saving realized % (Fixed v calculated or measured)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'System control pressure reduced (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Volume Flow Found (cfm)',
          VOL_FLOW_REPAIRED: 'Volume Flow Repaired (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energy saving opportunity (Found) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: 'Energy saving realized (Fixed) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'System control pressure reduced (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Performance Indicator',
          ENPI_VALUE: 'EnPI Value',
          ENB_VALUE: 'EnB Value',
          SUCCESS_THRESHOLD: 'Success Threshold (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Assigned To',
        assignedBy: 'Assigned By',
        assignedDate: 'Assigned Date',
        requestReassign: 'Request Reassign',
        actionStatus: 'Action Status',
        partsNeeded: 'Part Required',
        productionOutage: 'Shutdown Requested',
        timeToRepair: 'Repair Time',
        repairCost: 'Repair Cost',
        closeOutRef: 'Close Out Ref',
        'assignmentRecord.documentId': 'Record ID',
        assignmentId: 'Assignment ID',
        owner: 'Owner',
        companyName: 'Company',
        assignmentActive: 'Assignment Status',
        'assignmentRecord.leakStatus.building': 'Unit/Equipment',
        'assignmentRecord.leakStatus.facility': 'Facility',
        'assignmentRecord.leakStatus.routeName': 'Route',
        'assignmentRecord.leakStatus.leakTag': 'Smart Tag #/Work Ref. #',
        'assignmentRecord.currency': 'Currency',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Action Taken',
        CLOSE_OUT_REF: 'Action Close-out Ref.#',
        PARTS_NEEDED: 'New part/component required',
        PRODUCTION_OUTAGE: 'Production interrupted',
        REPAIR_COST: 'Cost ($)',
        REQUEST_REASSIGN: 'Request reassign',
        ACTION_STATUS: 'Action status',
        TIME_TO_REPAIR: 'Time taken to Repair (mins)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Flow by leak category',
          LOSS_BY_LEAK: 'Loss by leak category',
          TAGS_BY_LEAK: 'Tags by leak category',
          AVG_FLOW_BY_LEAK: 'Average Flow by leak category',
          SAVING_OPT_BY_REPAIR: 'Opportunity and savings by time to repair',
          FLOW_REPAIR_TIME: 'Flow by time to repair',
          LOSS_REPAIR_TIME: 'Loss by time to repair',
          FLOW_REPAIR_PROG: 'Flow by repair progress',
          TOTAL_REPAIR_PROG: 'Loss by repair progress',
          FLOW_BY_EQUIP_COMP: 'Flow by equipment/component',
          LOSS_BY_EQUIP_COMP: 'Loss by equipment/component',
          TAGS_BY_EQUIP_COMP: 'Tags by equipment/component',
        },
        leakCategoryLabels: {
          LOW: 'Low',
          MEDIUM: 'Medium',
          HIGH: 'High',
        },
        actionStatus: {
          OPEN: 'Open',
          COMPLETE: 'Complete',
        },
        equipmentTypes: {
          CONNECTOR: 'Connector',
          CONTROL_VALVE: 'Control Valve',
          HOSE: 'Hose',
          TUBE_FITTING: 'Tube Fitting',
          METER: 'Meter',
          OPEN_ENDED_LINE: 'Open-ended line',
          PRESSURE_RELIEF_VALVE: 'Pressure relief valve',
          PUMP_SEAL: 'Pump seal',
          REGULATOR: 'Regulator',
          VALVE: 'Valve',
          LEVEL_CONTROLLER: 'Level controller',
          POSITIONER: 'Positioner',
          PRESSURE_CONTROLLER: 'Pressure controller',
          TRANSDUCER: 'Transducer',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Generic pneumatic instrument',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Generic reciprocating compressor',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Generic centrifugal compressor',
        },
        mainGasValues: {
          AIR: 'Air',
          METHANE: 'Methane (CH4)',
          CARBON_DIOXIDE: 'Carbon Dioxide (CO2)',
          HYDROGEN: 'Hydrogen (H2)',
          HELIUM: 'Helium (He)',
          NITROGEN: 'Nitrogen (N2)',
          REFRIGERANT: 'Refrigerant (HFCs)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oxygen (O)',
          WATER: 'Water (Steam)',
        },
        leakTypeValues: {
          DEFAULT: 'None selected',
          HOLE: 'Hole',
          CRACK: 'Crack',
          FITTING: 'Fitting',
          FLANGE: 'Flange',
          'GLAND/SEAL': 'Gland/Seal',
          THREAD: 'Thread',
          'TUBE/HOSE': 'Tube/Hose',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Compressed Air',
          VACUUM: 'Vacuum',
          STEAM: 'Steam',
          PROCESS_GAS: 'Process Gas',
          LIQUID: 'Liquid',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Rotary Screw',
          RECIPROCATING: 'Reciprocating',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrifugal',
        },
        regionValues: {
          NORTH_AMERICA: 'North America',
          OUTSIDE_NORTH_AMERICA: 'Outside North America',
        },
        locationTypeValues: {
          RESIDENTIAL: 'Residential',
          COMMERCIAL: 'Commercial',
          INDUSTRIAL: 'Industrial',
          MEDIUM_VOLTAGE: 'Medium Voltage',
          HIGH_VOLTAGE: 'High Voltage',
        },
        phaseValues: {
          SINGLE_PHASE: 'Single-phase',
          THREE_PHASE: 'Three-phase',
        },
        faultValues: {
          KNICKS_OR_VOIDS_IN_INSULATION: 'Knicks or Voids in Insulation',
          CORONA: 'Corona',
          TREEING: 'Treeing',
          TRACKING: 'Tracking',
          ARCING: 'Arcing',
          'DIRTY_POWER/POWER_DISTORTION': 'Dirty Power/ Power Distortion',
          MECHANICAL_LOOSENESS: 'Mechanical Looseness',
          DELAMINATION_OF_WINDINGS: 'Delamination of Windings',
          LOOSE_PANEL: 'Loose Panel',
          VIBRATING_NAMEPLATE: 'Vibrating Nameplate',
          ANTAGONISTIC_ULTRASOUND: 'Antagonistic Ultrasound',
          OTHER: 'Other',
        },
        electricEquipmentTypeValues: {
          MCC: 'MCC (Motor Control Equipment)',
          'TRANSFORMER,_DRY': 'Transformer, Dry',
          'TRANSFORMER,_OIL': 'Transformer, Oil',
          SWITCH_GEAR: 'Switch Gear',
          PANEL: 'Panel',
          BUSDUCT: 'Busduct',
          CABLE: 'Cable',
          CONNECTOR: 'Connector',
          BREAKER: 'Breaker',
          BUSHING: 'Bushing',
          TRANSMISSION_LINE: 'Transmission Line',
          'TRANSFORMER,_POLE': 'Transformer, Pole',
          BATTERY: 'Battery',
          BATTERY_BANK: 'Battery Bank',
          TERMINALS: 'Terminals',
          'TRANSFORMER,_STEP_UP': 'Transformer, Step Up',
          'TRANSFORMER,_STEP_DOWN': 'Transformer, Step Down',
          DISTRIBUTION_PANEL: 'Distribution Panel',
          BREAKER_PANEL: 'Breaker Panel',
          RELAYS: 'Relays',
          CAPACITORS: 'Capacitors',
          REACTORS: 'Reactors',
          UPS: 'UPS',
          GROUNDING: 'Grounding',
          POWER_METERS: 'Power Meters',
          CURRENT_TRANSFORMERS: 'Current Transformers',
          POTENTIAL_TRANSFORMERS: 'Potential Transformers',
          SURGE_PROTECTION: 'Surge Protection',
          CABLE_TRAYS: 'Cable Trays',
          EMERGENCY_LIGHTING: 'Emergency Lighting',
          CONTACTORS: 'Contactors',
          INVERTERS: 'Inverters',
          SOLAR_PANELS: 'Solar Panels',
          BATTERY_STORAGE_SYSTEMS: 'Battery Storage Systems',
        },
        recordTypes: {
          leak: 'Leak',
          electrical: 'Electrical',
        },
        labels: {
          LOW: 'Low',
          MEDIUM: 'Medium',
          HIGH: 'High',
          EQUIPMENT: 'Equipment',
          SAVINGS: 'Savings',
          OPPORTUNITY: 'Opportunity',
          LOSS: 'Loss',
          REPAIRED: 'Repaired',
          UNREPAIRED: 'Not repaired',
          LESS_15_DAYS: 'Less than 15 days',
          DAYS_16_TO_30: '16 to 30 days',
          DAYS_31_TO_60: '31 to 60 days',
          DAYS_61_TO_90: 'More than 60 days',
          LESS_THAN_15_DAYS: 'Repaired < 15 Days',
          '16_TO_30_DAYS': 'Repaired 16-30 Days',
          '31_TO_60_DAYS': 'Repaired 31-60 Days',
          MORE_THAN_60_DAYS: 'Repaired 61-90 Days',
          AIR: 'Air',
          METHANE: 'Methane (CH4)',
          CARBON_DIOXIDE: 'Carbon Dioxide (CO2)',
          HYDROGEN: 'Hydrogen (H2)',
          HELIUM: 'Helium (He)',
          NITROGEN: 'Nitrogen (N2)',
          REFRIGERANT: 'Refrigerant (HFCs)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oxygen (O)',
          WATER: 'Water (Steam)',
          DEFAULT: 'None selected',
          HOLE: 'Hole',
          CRACK: 'Crack',
          FITTING: 'Fitting',
          FLANGE: 'Flange',
          'GLAND/SEAL': 'Gland/Seal',
          THREAD: 'Thread',
          'TUBE/HOSE': 'Tube/Hose',
          COMPRESSED_AIR: 'Compressed Air',
          VACUUM: 'Vacuum',
          STEAM: 'Steam',
          PROCESS_GAS: 'Process Gas',
          LIQUID: 'Liquid',
          ROTARY_SCREW: 'Rotary Screw',
          RECIPROCATING: 'Reciprocating',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrifugal',
          CONNECTOR: 'Connector',
          CONTROL_VALVE: 'Control Valve',
          HOSE: 'Hose',
          TUBE_FITTING: 'Tube Fitting',
          METER: 'Meter',
          OPEN_ENDED_LINE: 'Open-ended line',
          PRESSURE_RELIEF_VALVE: 'Pressure relief valve',
          PUMP_SEAL: 'Pump seal',
          REGULATOR: 'Regulator',
          VALVE: 'Valve',
          LEVEL_CONTROLLER: 'Level controller',
          POSITIONER: 'Positioner',
          PRESSURE_CONTROLLER: 'Pressure controller',
          TRANSDUCER: 'Transducer',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Generic pneumatic instrument',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Generic reciprocating compressor',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Generic centrifugal compressor',
        },
        leakRecordPDF: 'Record • Exported {{date}}',
        leakTag: 'Smart Tags • Exported {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Compressor Tag',
          COMPRESSOR_MAKE: 'Compressor Make',
          COMPRESSOR_MODEL: 'Compressor Model',
          COMPRESSOR_TYPE: 'Compressor Type',
          NAMEPLATE_HP: 'Nameplate HP',
          NAMEPLATE_CAPACITY: 'Nameplate Capacity (m³/h)',
          MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
          MOTOR_VOLTAGE: 'Motor Voltage',
          FULL_LOAD_CURRENT: 'Full Load Current (Amps)',
          FULL_LOAD_INPUT_POWER: 'Full Load Input Power (kW)',
          RATED_PRESSURE: 'Rated Pressure (kPag)',
          PRESSURE_SETPOINT: 'Pressure Setpoint (kPag)',
          CONTROL: 'Control',
          TOTAL_AIR_STORAGE_SIZE: 'Total Air Storage Size (l)',
          IS_ACTIVE: 'Compressor Active / Off',
          OPERATION_TYPE: 'Trim / Baseload',
          PERCENT_LOAD: '% Load',
          COMPRESSOR_SETPOINT_SEU: 'Compressor Setpoint (kPag)',
          COMPRESSOR_FLOW_SEU: 'Compressor Flow (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'System Pressure Setpoint (kPag)',
          SYSTEM_MEASURED_FLOW: 'System Measured Flow (m³/h)',
          DATE_TIME_RUNNING_STATE: 'Date and Time of Running State',
          NOTES: 'Notes',
          NAMEPLATE: 'Compressor Name Plate',
          DATASHEET: 'Compressor Data Sheet',
          BHP: 'BHP',
          OPERATING_HOURS: 'Operating Hours',
          LOADED_PERCENT_TIME_RUNNING: '% Time Running',
          LOADED_PERCENT_FULL_LOAD_BHP: '% Full Load BHP (Capacity)',
          LOADED_MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
          UNLOADED_PERCENT_TIME_RUNNING: '% Time Running',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% Full Load BHP (Capacity)',
          UNLOADED_MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
          ENERGY_COST: 'Aggregate Electric Rate ($)',
          ANNUAL_ENERGY_COST: 'Annual Energy Cost ($)',
          ANNUAL_ENERGY_KWH: 'Annual Energy kWh',
          COST_FULLY_LOADED: 'Cost when Fully Loaded ($)',
          COST_UNLOADED: 'Cost when Unloaded ($)',
          KWH_PER_YEAR_LOADED: 'kWh per Year when Loaded',
          KWH_PER_YEAR_UNLOADED: 'kWh per Year when Unloaded',
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Nameplate Capacity (cfm)',
          FULL_LOAD_INPUT_POWER: 'Full Load Input Power (BTU/h)',
          RATED_PRESSURE: 'Rated Pressure (psi)',
          PRESSURE_SETPOINT: 'Pressure Setpoint (psi)',
          TOTAL_AIR_STORAGE_SIZE: 'Total Air Storage Size (gal)',
          COMPRESSOR_SETPOINT_SEU: 'Compressor Setpoint (psi)',
          COMPRESSOR_FLOW_SEU: 'Compressor Flow (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'System Pressure Setpoint (psi)',
          SYSTEM_MEASURED_FLOW: 'System Measured Flow (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: 'Utility Provider',
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: 'Unit',
          REBATE_COST: '$/kWh',
          REBATE_UNIT: 'Unit',
          ENERGY_SOURCES: 'Energy Sources',
          FE_HOURS_OPERATION: 'Hours of Operation',
          FE_ENERGY_COST: 'Cost of Energy ($/kWh)',
          FE_EFFICIENCY: 'Efficiency (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: 'Efficiency (kW/cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'Request validation error. Please check form fields',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Register a Device',
          help: 'Help',
          metric: 'Metric',
          imperial: 'Imperial',
          downloadApk: 'Download APK',
          downloadOta: 'Download File',
        },
        otaDialog: {
          title: 'Download File',
          downloadButton: 'Download',
          serialNumberLabel: 'Device Serial Number',
          serialNumberRequired: 'Serial number is required',
          fileDownloaded: 'File {{fileName}} downloaded successfully',
          errorFetchingFile: 'Error fetching file. Please try again later',
          selectSerialNumber: 'Select a device serial number',
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'You are on the Essential plan.',
            dialogTwo: 'Click ',
            dialogThree: 'here',
            dialogFour: 'to purchase a new Admin license or contact ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable: 'We are experiencing technical difficulties. Our service may be affected. Sorry for the inconvenience.',
          downForMaintenance: 'We are currently down for maintenance. Please try again later.',
          tagsManagement: {
            dialogOne: 'Purchase more',
            dialogTwo: 'PROSARIS Smart Tags',
            dialogThree: 'here!',
          },
        },
        mobileNav: {
          index: {
            menu: 'Menu',
            editProfile: 'Edit Profile',
            logout: 'Logout',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Clear All',
            readAll: 'Read All',
            noNotifications: 'No Notifications',
          },
        },
        index: {
          dashboard: 'Dashboard',
          records: 'Records',
          actions: 'Actions',
          company: 'Company',
          tags: 'Tags',
        },
        loggedInAs: 'Logged in as',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: 'Cannot determine label by field {{field}}',
        cannontDeterminByLabel: 'Cannot determine field by label {{label}}',
        active: 'Active',
        closed: 'Closed',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Dashboard',
        records: 'Records',
        actions: 'Actions',
        company: 'Company',
        tags: 'Tags',
        redirecting: 'Redirecting you...',
      },
      account: {
        index: {
          updatePassword: 'Successfully updated password.',
          accountDeletedSuccess: 'Account {{user}} has been successfully deleted',
          accountDeletedError: 'Cannot delete account at the moment. Please try again later.',
          authenticationError: 'Failed to authenticate user',
          personalDetails: 'Personal Details',
          currentPasswordIsRequired: 'Current password is required',
          username: 'Username',
          changePassword: 'Change Password',
          currentPassword: 'Current Password',
          profilePicture: 'Profile Picture',
          dangerZone: 'Danger Zone',
          integrations: 'Integrations',
          sosShipments: 'SOS Inventory',
          sosSyncMessage:
            'This process triggers the SOS order processing in Prosaris with the latest data from the SOS Inventory. While this process is typically managed automatically every 4 hours through a scheduled task, using this button allows you to initiate the process manually.',
          sosSyncSuccess: 'SOS Sync has been triggered successfully',
          triggerSosSync: 'Trigger SOS Sync',
          hubspot: 'HubSpot',
          hubspotSyncMessage:
            'This process instantly pushes the latest data from the platform to HubSpot. This synchronization occurs automatically every hour through a scheduled task, but clicking the button allows you to trigger the sync manually whenever needed.',
          hubspotSyncSuccess: 'Hubspot Sync has been triggered successfully',
          triggerHubspotSync: 'Trigger Hubspot Sync',
          deleteAccountTitle: 'Delete Account',
          deleteAccountMessage: ' The account will no longer be available, and all the personal information will be permanently deleted.',
          alertTitle: 'Please confirm account deletion',
          deleteDialog: {
            dialogTwo: 'Deleting your account will permanently remove all of your personal information from our database.',
            dialogThree: 'THIS ACTION CANNOT BE UNDONE.',
            dialogFour: 'Please enter your password to continue.',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'Cache cleared successfully!',
          editProfile: 'Edit Profile',
          clearCache: 'Clear Cache',
          alertTitle: 'Please confirm clearing app cache',
          deleteAll: 'Delete all',
          accordionDialog: {
            dialogOne: 'This will delete all stored settings for the following pages:',
            dialogTwo: 'Charts, Chart Filters, Timeframe',
            dialogThree: 'Filters, Sorting, Columns, Preview',
            dialogFour: 'Filters, Columns',
            dialogFive: 'Selected company',
            dialogSix: 'Filters, Columns',
          },
          deleteSelective: 'Delete selective',
          selectiveDialog: 'Please select the stored settings you want to delete:',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Timeframe',
            CHART_FILTERS: 'Chart Filters',
            CHARTS: 'Charts',
          },
          Records: {
            HIGHLIGHTED: 'Preview',
            COLUMNS: 'Columns',
            FILTERS: 'Filters',
            SORT_COLUMN: 'Sorting',
          },
          Actions: {
            FILTERS: 'Filters',
            COLUMNS: 'Columns',
          },
          Company: {
            SELECTED: 'Selected company',
          },
          Tags: {
            FILTERS: 'Filters',
            COLUMNS: 'Columns',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Company',
          'leakStatus.building': 'Unit/Equipment',
          'leakStatus.facility': 'Facility',
          'leakStatus.leakTag': 'Smart Tag #/Work Ref. #',
        },
        tableBody: {
          noAssignmentsToDisplay: 'There are no assignments to display',
        },
        filtersCommonWords: {
          recordsFilter: 'Records Filter',
          column: 'Column',
          selectAColumn: 'Select a column...',
          addSearchTerm: 'Add a search term...',
          filtersArentAvailable: 'Filters are not available',
          value: 'Value',
          exportCSV: 'Export CSV',
          show: 'Show',
          loadMoreRecords: 'Load More Records',
          selectedAllRecords: 'Selected all records ({{totalNum}})',
          selectedFromRecords: 'Selected {{selectedNum}} from {{totalNum}} records',
          reset: 'Reset',
        },
        invalidColumn: 'Invalid column {{column}}',
        assignedActionRecords: 'Assigned Action Records',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'Forgot your password?',
          successMessage: "We've sent a confirmation email to",
        },
        authSuccess: {
          thanks: 'Thanks!',
          backToLogin: 'Back to Prosaris Log In',
        },
        loginView: {
          forgotPass: 'Forgot Password?',
          createAccount: 'Create an account',
          passwordIsRequired: 'Password is required',
          resetPassword: 'Rest password',
          contactSupport: 'Please contact support@prosaris.ca',
          incorrectEmailOrPassword: 'Incorrect email or password',
          resetPassDialog: {
            dialogOne: 'You are required to reset your password before continuing.',
            dialogTwo: 'A password reset email has been sent to you with instructions.',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'Password was successfully reset',
          enterNewPassword: 'Enter your new password below.',
          passwordChanged: 'Your password has been changed.',
        },
        createCompanyView: {
          createCompany: 'Create a Company',
          companyName: 'Company Name',
          successCreatingCompany: 'Your company has been created.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Unexpected error occur loading leak data',
          calculatingError: 'Unexpected error occur during calculation',
          dbMeasured: 'Db Measured',
          calculate: 'Calculate',
          calculating: 'Calculating...',
          outflowCoefficient: 'Outflow Coefficient',
          kwhPerYear: 'Kwh/year',
        },
      },
      companies: {
        commonUsedWords: {
          assignedAdminLicenses: 'Assigned Admin Licenses',
          company: 'Company',
          totalAccounts: 'Total Accounts',
          email: 'Email',
          name: 'Name',
          loadCompanyUsersError: 'Failed to load company users. Please try again later',
          activated: 'Activated',
          deactivated: 'Deactivated',
          activating: 'Activating',
          deactivating: 'Deactivating',
          deactivate: 'Deactivate',
          activate: 'Activate',
          set: 'Set',
          youAreGoingTo: 'You are going to',
          areYouSure: 'Are you sure?',
          companyNameIsRequired: 'Company name is required',
          editCompany: 'Edit Company',
          createCompany: 'Create Company',
          viewHistory: 'View History',
          exportCSV: 'Export CSV',
          onEssentialPlan: 'You are on an essential plan. Please contact support@prosaris.ca',
        },
        groupPerformance: {
          title: 'Group Performance',
          normalizeData: 'Normalize Data',
          selectTimeline: 'Select Timeline',
          selectCriteria: 'Select Criteria',
        },
        charts: {
          chart: {
            criteria: {
              KWH: 'Total kWh/year',
              LOSS: 'Total Loss ($/year)',
              FLOW: 'Total Mass Flow',
            },
            noDataAvailable: 'No data available',
            topNCompanies: 'Top {{numCompanies}} Companies',
          },
        },
        accounts: {
          accountDialog: {
            companyIsRequired: 'Company is required',
            roleIsRequired: 'Role is required',
            createAccountSuccessMessage: 'Successfully created account',
            emailTakenErrorMessage: 'Account with this email already exist in a system',
            errorWhileCreatingAccountMessage: 'Error while creating an account. Please try again later.',
            changeAccountSuccessMessage: 'Successfully updated account',
            errorWhileChangingAccountMessage: 'Error while updating account. Please try again.',
            editAccount: 'Edit Account',
            createAccount: 'Create Account',
            role: 'Role',
          },
          changeOwner: {
            changeOwnerMessage: 'Do you want also to change owner for leak records related to this account?',
            no: 'No, keep current account in place',
          },
          contentCell: {
            loadAccountRolesError: 'Failed to load account roles information. Please try again later.',
            changeRoleSuccessMessage: 'Account {{email}} has a new role',
            changeRoleErrorMessage: 'Error while updating role for account {{email}}. Please try again later.',
            editUser: 'Edit User',
            adminSetPassword: 'Admin Set Password',
            adminSetSubRenewalDate: 'Admin Set Subscription Renewal Date',
            resetPassword: 'Reset Password',
            user: 'User',
            deleteUser: 'Delete User',
          },
          index: {
            noUsersToDisplay: 'There are no users to display',
            changeStatusSuccessMessage: 'Successfully {{isActive}} user',
            changeStatusErrorMessage: 'Error while {{isActive}} user. Please try again',
            changeOwnerSuccessMessage: 'Records were transferred to a new owner',
            changeOwnerErrorMessage: 'Cannot transfer records to a new owner. Please try again later',
            deleteAccountMutationSuccessMessage: 'Account {{email}} has been successfully deleted',
            deleteAccountMutationErrorMessage: 'Cannot delete {{email}} account at the moment. Please try again later',
            resetAccountPassWordSuccessMessage: 'Password reset email sent successfully.',
            users: 'users',
            noneAvailable: 'None available',
            of: 'of',
            contactSupport: 'Please contact support@prosaris.ca for additional information about company admin licenses',
            findAccount: 'Find Account',
            placeholder: 'Type name or email...',
            tooltip: 'You require at least one company admin license before you can invite users',
            invite: 'Invite',
            alertDialog: {
              messageOne: "Please confirm user's reset password request",
              messageTwo: 'Please confirm account status change',
              messageThree: 'Please confirm account deletion',
            },
            sendAPasswordResetEmail: 'You are going to send a password reset email for the following account:',
            confirmMessage: 'Do you want to proceed?',
            followingAccount: 'following account:',
            delete: 'DELETE',
            userCreatedDialogTitle: 'User Password Reset Link Sent Successfully',
            userCreatedDialogDescription:
              'The user has been sent a password reset link to their email address. You can also copy the link below.',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'Successfully updated account password',
            changedUserPasswordErrorMessage: 'Error while updating account. Please try again.',
            setNewPassword: 'Set New Password',
            accountPasswordUpdated: 'Account Password Updated',
            accountPasswordUpdatedDescription:
              'The account password has been updated successfully. You can click the button below to copy the new password.',
          },
          adminSetSubRenewalDate: {
            title: 'Set Subscription Renewal Date',
            subRenewalDate: 'Subscription Renewal Date',
            changedSubRenewalDateSuccessMessage: 'Successfully updated subscription renewal date',
            changedSubRenewalDateErrorMessage: 'Error while updating account. Please try again.',
          },
          setPassword: {
            automatic: 'Automatically generate a strong password',
            manual: 'Create password manually',
            copyLinkInstruction: "You'll be able to view and copy the password in the next step",
          },
          userCreatedDialog: {
            title: 'User Created Successfully',
            description:
              'Your new user can now start using Prosaris dashboard. The user will also receive a link via email to reset their password.',
            passwordCopied: 'Password copied to clipboard',
            passwordResetLinkCopied: 'Reset password link copied to clipboard',
            copyPassword: 'Copy Password',
            copyPasswordResetLink: 'Copy Password Reset Link',
          },
          useRoleOptions: {
            notAvailable: '(not available)',
            adminSeatTaken: '(admin seat is taken)',
            allSeatsAreTaken: '(all {{numSeats}} seats are taken)',
            adminRolesTitle: 'Admin Roles',
            roles: {
              superAdmin: 'Prosaris Admin',
              companyAdmin: 'Company (Admin)',
              superCompanyAdmin: 'Corporate (Admin)',
              companyRead: 'Company (Read)',
              superAdminRead: 'Prosaris Admin (Read)',
              integrator: 'Integrator',
              auditor: 'Auditor',
              user: 'User',
              essential: 'Prosaris Essential',
            },
            rolesDescriptions: {
              superAdmin: 'Allowed to have full control on multiple companies.',
              companyAdmin: 'Allowed to view, edit and assign company records.',
              superCompanyAdmin: 'Allowed to have full control on company records and users.',
              companyRead: 'Allowed to view company records in read-only mode.',
              superAdminRead: 'Allowed to have read-only access to all companies.',
              integrator: 'Allows third-party integrations to manage companies and accounts.',
              auditor: 'To allow auditing of leak records.',
              user: 'Allowed to create/update/delete own records.',
              essential: 'A role with limited functionality.',
            },
            nonAdminRolesTitle: 'Non-Admin Roles',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Failed to load audits. Please try again later',
            audits: 'audits',
            noAuditsToDisplay: 'There are no audits to display',
            findByAuditorEmail: 'Find by Auditor Email',
            placeholder: 'Type auditor email...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: 'Facility Name is required',
              hoursYearlyIsRequired: 'Total Hours/Year is required',
              totalHoursYearly: 'Total Hours/Year cannot exceed 8760',
              invalidPhoneNumber: 'Invalid phone number',
              createErrorMessage: 'Hours x Shifts cannot exceed 24hrs',
              loadFacilityErrorMessage: 'Failed to load facility. Please try again later',
              createFacilitySuccessMessage: 'Successfully created facility',
              createFacilityErrorMessage: 'Error while creating a facility. Please try again later.',
              changeFacilitySuccessMessage: 'Successfully updated facility',
              changeFacilityErrorMessage: 'Error while updating facility. Please try again.',
              facilityName: 'Facility Name',
              abbreviation: 'Abbreviation',
              address: 'Address',
              enterALocation: 'Enter a location',
              tooltip: 'for Energy Management activities, also Report Approver',
              energyMgtOwner: 'Energy Mgt. Owner',
              title: 'Title',
              phone: 'Phone',
              tooltipTwo: 'for solution renewals and general account issues',
              accountOwner: 'Account Owner',
              manufacturingArea: 'Manufacturing Area',
              size: 'Size',
              unit: 'Unit',
              hoursOfOperation: 'Hours of Operation',
              auditFrequency: 'Audit Frequency',
            },
            operationHours: {
              daysPerWeek: 'Days/Week',
              shiftsPerDay: 'Shifts/Day',
              hoursPerShift: 'Hours/Shift',
              weeksPerYear: '52 weeks/year',
              totalHoursYearly: 'Total = Hours/Year',
            },
            constants: {
              constant: 'Constant',
              monthly: 'Monthly',
              threeMonth: '3 Month',
              fourMonth: '4 Month',
              sixMonth: '6 Month',
              nineMonth: '9 Month',
              annual: 'Annual',
              sqft: 'Square Feet [SQFT]',
              sqm: 'Square Meters [SQM]',
            },
          },
          index: {
            users: 'Users',
            devices: 'Devices',
            facility: 'Facility',
            energy: 'Energy',
            seu: 'SEUs - Comp.',
            enpi: 'EnPIs/EnBs',
            routes: 'Routes',
            audit: 'Audits',
            comingSoon: 'Coming Soon...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'Failed to load EnPIs. Please try again later',
            createEnpisSuccessMessage: 'Successfully created EnPIs',
            createEnpisErrorMessage: 'Error while creating EnPIs. Please try again later.',
            changeEnpisSuccessMessage: 'Successfully updated EnPIs',
            changeEnpisErrorMessage: 'Error while updating EnPIs. Please try again.',
            showHistory: 'Show History',
          },
          changeHistory: {
            bulkOperation: 'as a bulk operation',
            details: 'details',
            created: 'created',
            updated: 'updated',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'History',
            noHistory: 'No History',
          },
        },
        energy: {
          index: {
            utilProvider: 'Utility Provider',
            energyUse: 'Energy Use & Consumption Type',
            error: {
              loadFacility: 'Failed to load facility. Please try again later',
              loadEnergy: 'Failed to load energy data. Please try again later',
              loadUtilProviderLogo: 'Failed to load utility provider logo. Please try again later',
              createEnergy: 'Failed to create energy data. Please try again later',
              createUtilProviderLogo: 'Failed to upload utility provider logo. Please try again later',
              updateEnergy: 'Failed to update energy data. Please try again later',
              deleteUtilProviderLogo: 'Failed to delete utility provider logo. Please try again later',
            },
            success: {
              createEnergy: 'Successfully created energy data',
              updateEnergy: 'Successfully updated energy data',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: 'Cost of Energy',
              incentiveRate: 'Incentive Rate',
              energySources: 'Energy Sources',
              per: 'per',
              validations: {
                utilProviderIsRequired: 'Utility Provider is required',
                costOfEnergyIsRequired: 'Cost of Energy is required',
                costOfEnergyMustBeNumber: 'Cost of Energy must be a number',
                costOfEnergyMustBeGreaterThanZero: 'Cost of Energy must be greater than 0',
                costOfRebateMustBeAtLeatZero: 'Cost of Rebate must be at least 0',
                costOfRebateMustBeNumber: 'Cost of Rebate must be a number',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: 'File size must be less than 1 MB',
                preview: 'Failed to preview image',
              },
              logo: 'Logo',
              addLogo: 'Add Logo',
              removeLogo: 'Remove Logo',
            },
            EnergySources: {
              energySource: 'Energy Source',
              percentage: 'Percentage',
              selectEnergySource: 'Select energy source',
              addEnergySource: 'Add energy source',
              totalPercentage: 'Total percentage',
              mustBeEqualTo100: '(must equal 100%)',
              constants: {
                energySources: {
                  NATURAL_GAS: 'Natural Gas',
                  FUEL_OIL: 'Fuel Oil',
                  DIESEL: 'Diesel',
                  GASOLINE: 'Gasoline',
                  PROPANE: 'Propane',
                  COAL: 'Coal',
                  WIND: 'Wind',
                  GEOTHERMAL: 'Geothermal',
                  BIOMASS: 'Biomass',
                  STEAM: 'Steam',
                  COMPRESSED_AIR: 'Compressed Air',
                  CHILLED_WATER: 'Chilled Water',
                  NUCLEAR: 'Nuclear',
                  HYDRO: 'Hydro',
                  SOLAR: 'Solar',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: 'Current Calculation Approach',
              currentCalcApproachMessage:
                "This calculation approach is currently applied to all 'Cost of Energy' fields by default, unless the user has overridden it.",
              alertTitle: 'Reset Calculation Approach',
              alertMessageOne:
                "This action will stop using the current calculation approach for all 'Cost of Energy' fields going forward.",
              alertMessageTwo: 'Please note that this change will not affect any existing records.',
              alertMessageThree: 'Are you sure you want to proceed with the reset?',
            },
            ReportType: {
              calcApproach: 'Calculation Approach',
              constants: {
                calculations: {
                  FIXED: 'Fixed Efficiency',
                  USDOE: 'US DoE',
                  NREL: 'NREL Chapter 22',
                },
              },
              setAsCurrent: 'Set as Current',
              setAsCurrentMessage: "This calculation approach will be used for all 'Cost of Energy' fields going forward.",
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: 'Efficiency must be greater than or equal to 0',
                  efficiencyMustBeNumber: 'Efficiency must be a number',
                  operationHoursMustBeGreaterThanZero: 'Hours of operation must be greater than or equal to 0',
                  operationHoursMustBeLessThan8760: 'Hours of operation must be less than 8760',
                  operationHoursMustBeNumber: 'Hours of operation must be a number',
                },
              },
              UsDoe: {
                loaded: 'Loaded',
                unloaded: 'Unloaded',
                calcSummary: 'Calculation Summary',
                currentOperatingSystems: 'Current Operating Systems',
                noCompsFoundAddCompOne: 'No compressors found. Add a compressor from the',
                noCompsFoundAddCompTwo: 'tab above.',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Error uploading name plate. Please try again later.',
            dataSheetError: 'Error uploading data sheet. Please try again later.',
            createSuccess: 'Compressor {{tag}} created',
            createError: 'Failed to create compressor. Please try again later.',
            createCompressor: 'Create Compressor',
          },
          compressorFile: {
            tip: 'Add File',
            removeFile: 'Remove File',
            removeImage: 'Remove Image',
            title: 'Please confirm compressor {{prop}} deletion',
            proceed: 'Do you want to proceed?',
          },
          compressorForm: {
            compTagIsReq: 'Compressor Tag is required',
            hpMustBeNum: 'Nameplate HP must be a number',
            capMustBeNum: 'Nameplate Capacity must be a number',
            motorEffMustBeNum: 'Motor Efficiency must be a number',
            motorVoltMustBeNum: 'Motor Voltage must be a number',
            loadMustBeNum: 'Full Load Current must be a number',
            loadInputMustBeNum: 'Full Load Input Power must be a number',
            pressureMustBeNum: 'Rated Pressure must be a number',
            setpointMustBeNum: 'Pressure Setpoint must be a number',
            airMustBeNum: 'Total Air Storage Size must be a number',
            addImage: 'Add Image',
            addFile: 'Add File',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Rotary Screw',
              RECIPROCATING: 'Reciprocating',
              CENTRIFUGAL: 'Centrifugal',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Load/Unload – Oil-Free',
              loadUnload1Gal: 'Load/Unload (1 gal/CFM)',
              loadUnload3Gal: 'Load/Unload (3 gal/CFM)',
              loadUnload5Gal: 'Load/Unload (5 gal/CFM)',
              loadUnload10Gal: 'Load/Unload (10 gal/CFM)',
              ivmWithBlowdown: 'Inlet Valve Modulation (w/o Blowdown)',
              ivmWithoutBlowdown: 'Inlet Valve Modulation (w/Blowdown)',
              variableDisplacement: 'Variable Displacement',
              vsdWithUnloading: 'VSD w/Unloading',
              vsdWithStopping: 'VSD w/Stopping',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'On/off control',
              reciprocatingLoadUnload: 'Load/Unload',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV w/blowdown',
              centrifugalInletIbv: 'IGV w/blowdown',
            },
          },
          contentCell: {
            min: '% must be at least 0',
            max: '% cannot be more than 100',
            max150: '% cannot be more than 150',
            typeError: 'Load must be a valid percentage',
            setpointMustBeNum: 'Compressor Setpoint must be a number',
            flowMustBeNum: 'Compressor Flow must be a number',
            pressureSetpointMustBeNum: 'System Pressure Setpoint must be a number',
            measuredFlowMustBeNum: 'System Measured Flow must be a number',
            dateTime: 'Select Date and Time',
            active: 'ACTIVE',
            off: 'OFF',
            trim: 'trim',
            baseLoad: 'baseLoad',
          },
          index: {
            error: 'Failed to load compressors. Please try again later',
            nameplateError: 'Failed to load name plate. Please try again later',
            dataSheetError: 'Failed to load data sheet. Please try again later',
            changeCompSuccess: 'Successfully updated compressor',
            changeCompError: 'Error while updating compressor. Please try again.',
            deleteCompSuccess: 'Successfully deleted compressor',
            deleteCompError: 'Error while deleting compressor. Please try again.',
            createCompNameplateSuccess: 'Successfully uploaded compressor name plate',
            createCompNameplateError: 'Error uploading name plate. Please try again later.',
            deleteCompNameplateSuccess: 'Successfully deleted compressor name plate',
            deleteCompNameplateError: 'Error while deleting compressor name plate. Please try again.',
            createCompDataSheetSuccess: 'Successfully uploaded compressor data sheet',
            createCompDataSheetError: 'Error uploading data sheet. Please try again later.',
            deleteCompDataSheetSuccess: 'Successfully deleted compressor data sheet',
            deleteCompDataSheetError: 'Error while deleting compressor data sheet. Please try again.',
            changeCompanyCompSuccess: 'Successfully updated system data',
            changeCompanyCompError: 'Error while updating system data. Please try again.',
            compDesignData: 'Compressor Design Data',
            comp: 'Compressors:',
            addComp: 'Add Compressor',
            editCompPermission: 'You need an admin license to edit the compressor. Please contact',
            selectOption: 'Select an option',
            undoChanges: 'Undo Changes',
            selectComp: 'Select a compressor from above.',
            noCompsFound: 'No compressors found.',
            compRunningState: 'Compressor Running State',
            noCompsFoundAddComp: 'No compressors found. Add a compressor from the section above.',
            title: 'Please confirm compressor deletion',
            areYouSure: 'Are you sure you want to',
            compressor: 'compressor',
            delete: 'delete',
          },
        },
        devices: {
          contentCell: {
            device: 'Device',
            usedByMultipleCompanies: 'is used by multiple companies',
          },
          index: {
            noDevicesToDisplay: 'There are no devices to display',
            loadingDevicesErrorMessage: 'Failed to load  company devices. Please try again later',
            changeStatusSuccessMessage: 'Successfully {{isActive}} device',
            changeStatusErrorMessage: 'Error while {{isActive}} device. Please try again',
            devices: 'devices',
            findDevice: 'Find Device',
            placeholder: 'Type device id...',
            activatedOn: 'Activated On',
            title: 'Please confirm device status change',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} has been successfully deleted',
          deleteCompanyErrorMessage: 'Failed to delete {{companyName}}. Please try again later.',
          needAdminLicense: 'You need an admin license to access this feature. Please contact support@prosaris.ca',
          deleteChildCompanyFirst:
            'Cannot delete company with child companies or accounts. Please delete all child companies and accounts first.',
          editSettings: 'Edit Settings',
          createASubCompany: 'Create a sub-company for {{companyName}}',
          deleteCompany: 'Delete Company',
          cannotCreateChildCompanyDialog: {
            messageOne:
              'You have reached the maximum number of companies that can be created by your Corporate account(s). Please contact ',
            messageTwo: 'for additional details.',
          },
          deleteCompanyDialog: {
            title: 'Please confirm company deletion',
            delete: 'Delete',
            deleting: 'Deleting',
            permanentlyDeleted: 'will permanently remove it from our database.',
            cannotBeUndone: 'THIS ACTION CANNOT BE UNDONE.',
          },
        },
        companyDialog: {
          industryIsRequired: 'Industry is required',
          createLogoErroerMessage: 'Error uploading image. Please try again later.',
          deleteLogoErrorMessage: 'Error deleting image. Please try again later.',
          createCompanySuccessMessage: 'Company {{name}} created',
          createCompanyErrorMessage: 'Failed to create a company. Please try again later.',
          changeCompanySuccessMessage: 'Company {{name}} updated',
          changeCompanyErrorMessage: 'Failed to change company parameter. Please try again later.',
          language: 'Language',
          industry: 'Industry',
          country: 'Country',
          currency: 'Currency',
        },
        companyLogo: {
          setImageErrorMessage: 'File size must be less than 1 MB',
          logo: 'Logo',
          add: 'Add',
          removeLogo: 'Remove Logo',
          addLogo: 'Add Logo',
        },
        index: {
          loadCompaniesErrorMessage: 'Failed to load companies list. Please try again later',
          changeCompaniesSectionWidth: 'Change Companies Section Width',
        },
        sidebar: {
          filterCompanies: 'Filter Companies',
          placeholder: 'Enter company name',
          myCompany: 'My Company',
          childCompanies: 'Child Companies',
        },
        routes: {
          index: {
            noFacility: 'You do not have a facility defined',
            createAFacility: 'Create a facility to start planning routes',
            goToCreateAFacility: 'Go to create a facility',
            createAFacilityTooltip: 'You need to create a facility before you can start planning routes.',
            save: 'Save',
            reset: 'Reset',
            routePlans: 'Route Plans',
            loading: 'Loading...',
            noRoutesAdded: 'No routes added.',
            buildARouteTooltip: 'Build a route using the Route Builder.',
            createFloorplanSuccess: 'Success! Floorplan uploaded!',
            createRouteSuccess: 'Success! Route created!',
          },
          routeBuilder: {
            title: 'Route Builder',
            missingFields: 'Missing fields in the excel file',
            manuallyBuildARoute: 'Manually Build A New Route Plan',
            manualInstructions: 'You can build routes manually or by uploading an Excel file.',
            facility: 'Facility',
            route: 'Route',
            enterRoute: 'Enter Route',
            addFloorplan: 'Add Floorplan',
            floorplanInstructionsTooltip:
              'Add an image of a floorplan to help you build routes. The route will be superimposed on the floorplan when saved.',
            change: 'Change',
            location: 'Location',
            enterLocation: 'Enter Location',
            equipment: 'Equipment',
            enterEquipment: 'Enter Equipment',
            criticality: 'Criticality',
            enterCriticality: 'Enter Criticality',
            description: 'Description',
            enterDescription: 'Enter Description',
            addEquipment: 'Add Equipment',
            addRoute: 'Add Route',
            downloadTemplate: 'Download Template',
          },
          excelReader: {
            loadFromFile: 'Load From File',
            instructionsTooltip:
              'You can build routes by uploading an Excel file where each sheet represents a different location in a facility. Each location can have multiple routes.',
          },
          graph: {
            controls: {
              addFloorplan: 'Add Floorplan',
              toggleLabels: 'Toggle Labels',
              addWaypoint: 'Add Waypoint',
              addEquipment: 'Add Equipment',
              swapEquipment: 'Swap Equipment',
              showLeakStats: 'Show Record Stats',
              save: 'Save',
              delete: 'Delete',
            },
            routeStats: {
              title: 'Route Statistics',
              totalDollarLossPerYear: 'Total $ Loss Per Year:',
              totalKwhLossPerYear: 'Total Kwh Loss Per Year:',
              totalLeakCount: 'Total Record Count:',
              totalActiveOpen: 'Total Active Open:',
              totalActiveClosed: 'Total Active Closed:',
              noStatsAvailable: 'No statistics available:',
              close: 'Close',
            },
            nodeDetails: {
              edit: 'Edit',
              name: 'Name',
              description: 'Description',
              criticality: 'Criticality',
              equipmentInformation: 'Equipment Information',
              thisIsAWaypoint: 'This is a waypoint',
              activeOpenLeakCount: 'Active Open Record Count:',
              noActiveLeaksDetected: 'No active leaks detected',
              close: 'Close',
              nothingToSeeHere: 'Nothing to see here',
              seeLeakRecords: 'See Records',
            },
            swapEquipment: {
              title: 'Swap Equipment',
              selectEquipment: 'Select the equipment to swap:',
              selectFirst: 'Select the first equipment',
              selectSecond: 'Select the second equipment',
              swap: 'Swap',
              cancel: 'Cancel',
            },
            addNode: {
              title: 'Add',
              waypoint: 'Waypoint',
              equipment: 'Equipment',
              afterWhich: 'After which equipment?',
              selectPreceding: 'Select the preceding equipment:',
              selectEquipment: 'Select equipment',
              name: 'Name',
              description: 'Description',
              criticality: 'Criticality',
              add: 'Add',
              cancel: 'Cancel',
            },
            save: 'Save',
            delete: 'Delete',
            successRouteUpdated: 'Route updated successfully',
            errorRouteUpdated: 'Error updating route. Please try again later.',
            successRouteDeleted: 'Route deleted successfully',
            errorRouteDeleted: 'Error deleting route. Please try again later.',
            successFloorplanUpdated: 'Floorplan updated successfully',
            errorFloorplanUpdated: 'Error updating floorplan. Please try again later.',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Assigned Admin Licenses',
            of: 'of',
            edit: 'Edit',
          },
          essentialMode: {
            disable: 'disable',
            enable: 'enable',
            essential: 'Essential Mode:',
            enabled: 'ENABLED',
            disabled: 'DISABLED',
            moveCompany: 'Move company {{company}} into Essential Mode',
            affectedCompanies: 'Number of affected companies',
            affectedAccounts: 'Number of affected accounts',
            archivedLeak: 'Number of archived leak event records',
            applyingChanges:
              'By applying these changes accounts will obtain “Essential” role. Users will be able to see {{quota}} latest leak event records on a dashboard and mobile app. Other records will be put into archive.',
            essentialLabel: 'Change Essential for sub-companies',
            turnOffEssential: 'Turn off Essential Mode for company {{company}}',
            byApplyingChanges:
              'By applying these changes all accounts will obtain “User” role. Users will be able to see all their records. All records will be unarchived.',
          },
          index: 'Settings',
        },
        index: {
          loadCompanyError: 'Cannot load company information. Please try again later',
          companySlug: 'Company Slug',
          notSelected: 'Not Selected',
          country: 'Country',
          accounts: 'Accounts',
          currency: 'Currency',
          adminLicenses: 'Admin Licenses',
          totalCustomer: 'Total Customer Accounts in Tree',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Register your device',
          toolTip: 'Please locate the serial number on your registration card or the box.',
          serialNumberIsRequired: 'Serial number is required',
          oops: 'Oops!',
          deviceNotFound: 'Device serial number not found',
          deviceFoundNdAssociatedCompany: 'Your device was found and is associated with a company.',
          deviceFound: 'Your device was found.',
          goToLogin: 'Go to Login',
          contactSupport: 'Please contact support',
        },
        deviceErrorCodes: {
          'B13:001': 'Device serial number not found',
          'B13:002': 'Device already activated',
          'B13:003': 'Admin email not sent',
          'B13:004': 'Company not found',
          'B13:005': 'Company invalid',
          'B13:006': 'Invalid type',
        },
        registerAccountView: {
          registerAccount: 'Create a Prosaris Account',
          termsMustBeAccepted: 'The terms and conditions must be accepted.',
          saleType012Success: 'You can login to start using your device.',
          saleType3Success: 'You have been added as a basic user. Your Company Admins have been notified.',
          saleType4Success: {
            companyPlatformFound: 'Company Platform license found.',
            dialogOne: 'This device is associated with a new company platform license.',
            dialogTwo: 'Your company may already exist in our system.',
            dialogThree: 'If so, please contact your administrator.',
          },
          termsAndConditions: {
            dialogOne: 'I agree to the Prosaris',
            dialogTwo: 'Terms of Service',
            dialogThree:
              'and confirm that I’ve been given enough time to read it and understand that it contains important terms about my use of Prosaris software, like limiting the liability of Prosaris and how my data will be handled.',
          },
          acceptTerms: 'Accept Terms',
          registerAccountErrorCodes: {
            'B02:001': 'Invalid registration code',
            'B02:002': 'Invalid user company',
            'B02:003': 'Invalid user role',
            'B02:004': 'Email is already taken',
            'B02:005': 'Email is already taken',
            'B02:006': 'Unable to reset password for your account',
            'B02:007': 'Invalid user company',
            'B02:008': 'Profile photo missing',
            'B02:009': 'Account reset is required. Process has been started',
            'B02:010': 'Account has been deactivated',
            'B02:011': 'Profile photo invalid format',
            'B02:012': 'Device already registered to a company',
            'B02:013': 'Serial number is undefined',
            'B02:014': 'Invalid type',
            'B02:015': 'Reset password link expired',
            'B02:016': 'Cannot created an account for this company',
            'B02:017': 'Invalid role',
            'B02:018': 'Invalid role',
            'B02:019': 'Invalid user company',
            'B02:020': 'Invalid user role',
            'B02:021': 'User does not belong to this company',
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Serial numbers are required',
          failedToCopy: 'Failed serial numbers copied to clipboard',
          invalidFormat: 'One or more device(s) have invalid format. Please check and try again.',
          devices: 'Register your devices',
          deviceSerialNumber: 'Device Serial Number(s)',
          note: 'Enter each serial number on a new line or separated by a comma.',
          title: 'Please locate the serial number on your registration card or the box.',
          register: 'Register',
          deviceRegistrationStatus: 'Device Registration Status',
          success: 'Success',
          noDeviceFound: 'No devices found.',
          failed: 'Failed',
          copyFailed: 'Copy failed serial numbers to clipboard',
        },
      },
      errors: {
        notFoundView: {
          title: 'The page you are looking for isn’t here',
          description: 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
        },
        accountNotFound: {
          title: 'Account Not Found',
          description:
            'Please try again! if problem persist then either your account is currently inactive or may not been initialized by admin. Please contact your administrator.',
          logout: 'Logout',
        },
      },
      integration: {
        index: {
          loggingInError: 'Error logging into Prosaris.',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'No images available',
          activeActionRecord: {
            updateAssignmentSuccess: 'Assigned action has been updated.',
            updateAssignmentError: 'Error while updating assigned action.',
            deleteImageSuccess: 'Assigned image has been deleted.',
            deleteImageError: 'Error while deleting assigned action image.',
            confirmMarkAction: 'Do you really want to mark this action as complete ?',
            confirmDeleteImage: 'Do you really want to delete this image ?',
          },
          index: 'Error while loading assigned actions for a record.',
        },
        audio: {
          noAudioAvailable: 'No audio data available',
          errorDownloadingAudio: 'Error downloading audio. Please try again later.',
          playPause: 'Play / Pause',
          generateRegion: 'Generate Region',
          removeLastRegion: 'Remove Last Region',
          generateMarker: 'Generate Marker',
          removeLastMarker: 'Remove Last Marker',
          toggleTimeline: 'Toggle Timeline',
          download: 'Download',
        },
        audit: {
          updateAuditSuccess: 'Successfully updated the audit',
          updateAuditError: 'Error while saving comment. Please try again later.',
          createAuditSuccess: 'Successfully created the audit',
          createAuditError: 'Error while creating audit. Please try again later.',
          deleteAuditSuccess: 'Audit has been successfully deleted',
          deleteAuditError: 'Failed to delete audit. Please try again later.',
          noComment: 'No comments',
          reviewComments: 'Review Comments',
          reviewedBy: 'Reviewed By:',
          auditDate: 'Audit Date:',
          deleteAudit: 'Delete audit',
          reviewedNoComments: 'Reviewed No Comments',
          auditComments: 'Audit Comments',
          saveComment: 'Save Comment',
          discardChanges: 'Discard Changes',
        },
        changeHistory: {
          created: 'created',
          updated: 'updated',
          deleted: 'deleted',
          transferred: 'transferred',
          leak: 'Record',
          images: 'Image',
          audit: 'Audit',
          audio: 'Audio',
          history: 'History',
          noHistory: 'No History',
          bulk: 'as a bulk operation',
          details: 'details',
          unset: 'unset',
        },
        fft: {
          fft: 'FFT',
          fftAvg: 'FFT Average',
          notApplicable: 'N/A',
          chartLayers: 'chart-layers',
          avg: 'Average',
          all: 'All',
          playing: 'Playing...',
          showTimeSeries: 'Show Time Series',
        },
        form: {
          updateLeakSuccess: 'Successfully updated.',
          updateLeakError: 'Error while updating leak. Please try again later.',
          alertTitle: {
            partOne: 'Record is in',
            partTwo: 'read-only',
            partThree: 'mode.',
          },
          alertDialog: {
            dialogOne: 'However, following properties could be modified:',
            dialogTwo: 'Action Status',
          },
          GPSLocation: 'GPS Location',
          completedOn: 'Completed On:',
          lastSaved: 'Last Saved:',
        },
        images: {
          removeSuccess: 'Successfully deleted image.',
          removeError: 'Cannot delete leak record image. Please try again later',
          text: 'Are you sure you want to delete this image?',
        },
        index: {
          title: 'Record',
          loadingError: 'Error while loading leak record. Please try again.',
          unsavedMessage: "By pressing 'Ok' you confirm that all unsaved changes will be deleted",
          notAnAuditor: 'You are not an auditor',
          anEssential: 'You are on an essential plan',
          showHistory: 'Show History',
        },
        massFlow: {
          recalculating: 'Recalculating Flow/Loss according to the leak parameter settings...',
          warning: 'Input values have changed or calculation is out of date, hit `Recalculate` to refresh Flow and Loss values',
          calculatingError: 'Error while calculating mass flow. Please try again.',
          noValue: 'No Value',
          calculatingSuccess: 'Flow/Loss Recalculated',
          recalculate: 'Recalculate',
          calculatorVersion: 'Calculation version:',
          noteDialog: {
            dialogOne: 'Changing the leak parameter settings will update the Flow/Loss calculation. Check',
            dialogTwo: 'calculation insights',
            dialogThree: 'page for additional information',
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'Record has been changed',
          close: 'close',
          unsavedChanges: 'This record has unsaved changes. Please, consider saving those changes or remove them completely.',
          discardChanges: 'Discard changes',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: 'Device ID is required',
          dbPeakIsRequired: 'Peak DB measured is required',
          dbMin: 'Peak DB measured must be greater than or equal to 0',
          dbMax: 'Peak DB measured must be less than or equal to 95',
          dbError: 'Peak DB measured must be a number',
          imageUploadError: 'Error uploading image. Please try again later.',
          createLeakSuccess: 'Successfully added record',
          createLeakError: 'Error while creating a record. Please try again later.',
          basicInfo: 'Basic Information',
          flowInput: 'Flow Input',
          mainGas: 'Main Gas/Component of Interest',
          selectComponent: 'Select Component',
          selectLeakType: 'Select leak type',
          mightBeSomethingHere: 'not sure yet',
          energyLoss: 'Energy Loss',
          selectCompressorType: 'Select Compressor Type',
          noRoutes: 'No routes found',
          noEquipment: 'No equipment found',
          noLocations: 'No locations found',
          energyCost: 'Energy Cost',
          audioRecording: 'Audio Recording',
          leakSourceEquipment: 'Source',
          selectComponentType: 'Select component type',
          selectService: 'Select service',
          locationAndUser: 'Location and User',
          leakEventStatus: 'Event Status',
          low: 'Low',
          medium: 'Medium',
          high: 'High',
          open: 'Open',
          complete: 'Complete',
          reset: 'Reset',
        },
        formElectrical: {
          systemVoltages: 'System Voltages',
          source: 'Source',
        },
        images: {
          leakSourceImageCapture: 'Source Image Capture',
          add: 'Add',
        },
        index: {
          title: 'Create Record',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'From',
          to: 'To',
          qualifier: 'Qualifier',
          savedFilters: 'Saved Filters',
          filter: 'Filter',
          selectFilter: 'Select a filter...',
          filterName: 'Filter Name',
          filterNameRequired: 'Filter Name is required',
          filtersNotAvailable: 'Filters not available',
          saveFilterTitle: 'Save Filter',
          deleteSavedFilterTitle: 'Please confirm filter deletion',
          deleteSavedFilterMessage: 'Are you sure you want to delete the "{{savedFilterName}}" filter?',
          createFilterSuccess: "Filter '{{filter}}' created successfully. You can access it under Record Filters → Saved Filters",
          createFilterError: 'Error while creating filter. Please try again later.',
          deleteFilterSuccess: 'Filter deleted successfully',
          deleteFilterError: 'Error while deleting filter. Please try again later.',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Assign Record',
          selectAUserToAssign: 'Select a user that should be assigned to selected record(s)',
          filterByName: 'Filter by name',
          filterByEmployeeName: 'Filter by employees name',
          unexpectedError: 'Unexpected error occur',
          noUserFound: 'No user found',
          assigningActionForSelectedRecords: 'Assigning action for selected record(s)...',
        },
        modalBulkEdit: {
          titleSingleRecord: 'Bulk edit {{selectedRecordsCount}} record',
          title: 'Bulk edit {{selectedRecordsCount}} records',
          selectAField: 'Select a field',
          verifyAction:
            'You are about to update records that belong to other users. Please confirm by entering the number of selected records below.',
          matchCountError: 'Must match the number of selected records ({{selectedRecordsCount}})',
          moreThanOneType: 'Selected records have more than one type of records. Some fields may not be available for bulk edit.',
        },
        tableHead: {
          youAreSelecting: 'You are selecting:',
          visibleRecords: 'visible records',
          andAllUnloadedRecords: 'and all unloaded records.',
          allRecordsWillBeLoaded: 'All records will be loaded and selected.',
          thisCanTakeAWhile: 'This can take a while if you have a lot of records.',
          allRecords: 'All records including the invisible ones',
          selectAllRecords: 'Select all records.',
        },
        tableBody: {
          noRecordsToDisplay: 'There are no records to display.',
        },
        toolBar: {
          assign: 'Assign',
          onEssentialPlan: 'You are on an essential plan. Please contact support@prosaris.ca',
          bulkEdit: 'Bulk Edit',
          bulkEditSuccess: 'Successfully updated {{len}} record',
          bulkEditSuccessPlural: 'Successfully updated {{len}} records',
          bulkEditError: 'Error while updating records. Please try again later',
          exportPDF: 'Export PDF',
          needAdminLicense: 'You need an admin license to access this feature. Please contact support@prosaris.ca',
          create: 'Create',
          deleteRecordMessage: 'Are you sure you want to delete selected record?',
          deleteRecordMessagePlural: 'Are you sure you want to delete selected records?',
          permissionDenied: 'Permission denied!',
          notAllowedToDelete:
            "You have selected someone's record which you are not allowed to delete. Please remove it from the selection.",
          generatingPDF: 'Generating PDF...',
          cannotGeneratePDF:
            'Unfortunately, we cannot export {{numberSelected}} records in one PDF document right now. Please select up to {{limitRecords}} records.',
          cannotExportMessage: 'Cannot export {{numberSelected}} records',
          bulkEditLimitExceeded: 'Cannot edit more than {{maxAllowed}} records. Please contact support@prosaris.ca',
        },
        index: {
          cannotLoadLeakRecord: 'Sorry, we cannot load leak record right now',
          successfullyAssignedAllRecords: 'Successfully assigned all records',
          actionRecordAssigned: 'Action record assigned!',
          actionRecordAssignedPlural: 'Action records assigned!',
          errorWhileAssigningLeak: 'Error while assigning leak. Please try again later.',
          successfullyDeletedAllRecords: 'Successfully deleted all records',
          successfullyDeletedRecord: 'Successfully deleted {{length}} leak record',
          successfullyDeletedRecordPlural: 'Successfully deleted {{length}} leak records',
          errorOnRecordsDelete: 'Error on records delete. Please try again later',
          leakPreview: 'Preview',
          leakRecord: 'Record: ',
        },
      },
      tagList: {
        resetTag: 'Reset Tag',
        dataExport: {
          smartLeakTagsFormat: 'Smart Tags format',
          smartLeakTagsDescription: 'Contains default fields as present on the Smart Tags page',
          standardLeakRecordFormat: 'Standard Record format',
          standardLeakRecordDescription: 'Contains all fields related to the leak records',
        },
        filters: { smartLeakTagFilters: 'Smart Tag Filters' },
        index: {
          cannotLoadTags: 'Sorry, we cannot load Smart Tag right now',
          updateLeakSuccess: 'Smart Tag reset successful.',
          updateLeakError: 'Error while resetting Smart Tag. Please try again later.',
          bulkEditSuccess: 'Successfully reset {{len}} Smart Tag',
          bulkEditSuccessPlural: 'Successfully reset {{len}} Smart Tags',
          bulkEditError: 'Error while resetting Smart Tags. Please try again later.',
          tagsFileName: 'Smart Tags',
          leakFileName: 'Records',
        },
        tableBody: {
          title: 'Please confirm resetting the tag',
          alertDialog: {
            dialogOne: 'You are trying to',
            dialogTwo: 'reset tag',
            dialogThree: 'from a leak record with',
            dialogFour: 'Action Status: OPEN',
            dialogFive: 'Do you want to proceed?',
          },
        },
        toolBar: {
          resetTags: 'Reset Tags',
          title: 'Please confirm bulk resetting the tags',
          alertDialog: {
            dialogOne: 'Your selection contains',
            dialogTwo: 'record with',
            dialogTwoPlural: 'records with',
            dialogThree: 'Action Status: OPEN',
            dialogFour: 'Do you want to proceed?',
          },
          exportFormatCSV: 'Select an export format for CSV',
          exportFormatPDF: 'Select an export format for PDF',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Error while loading assigned actions. Please try again.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Avaliable charts',
            selectAChart: 'Select a chart...',
            chartFilters: 'Chart Filters',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Error while loading leak records. Please try again.',
              errorMessageTwo: 'Please select a date that is earlier than the end date.',
              errorMessageThree: 'Please select a date period lower than 6 months',
              errorMessageFour: 'Please select a date that is later than the start date.',
            },
            charts: 'Charts',
            chartAlreadyExists: 'This chart already exists!',
          },
          filters: {
            select: 'Select...',
            startDate: 'Start date',
            endDate: 'End date',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Flow by leak category (${unit})`,
              LOSS_BY_LEAK: (currency) => `Loss by leak category (${currency})`,
              TAGS_BY_LEAK: 'Tags by leak category',
              AVG_FLOW_BY_LEAK: (unit) => `Average Flow by leak category (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Loss and savings opportunity by repair time (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Flow by time to repair (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Loss by time to repair (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Flow progress – Repaired v. Unrepaired (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Loss progress – Repaired v Unrepaired (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Flow by equipment/component (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `Loss by equipment/component (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Tags by equipment/component',
            },
            datasetLabel: {
              totalLeakFlow: 'Total Leak Flow Found in',
              totalLoss: 'Total of Loss',
              totalRecords: 'Total Records Found',
              avgLeakFlow: 'Average Leak Flow Found in',
              totalLossIn: 'Total loss in',
              flowByRepairTime: 'Flow By repair time in',
              lossByRepairTime: 'Loss by repair time',
              flowByRepairProgress: 'Flow Found by Repair Progress in',
              recordsByCompletion: 'Records by completion',
              flowByEquipment: 'Flow Found by Equipment in',
              totalLossByEquipment: 'Total loss by Equipment',
              totalRecordsByEquipment: 'Total records by Equipment',
            },
            labels: {
              low: 'Low',
              medium: 'Medium',
              high: 'High',
              equipment: 'Equipment',
              savings: 'Savings',
              opportunity: 'Opportunity',
              loss: 'Loss',
              repaired: 'Repaired',
              unrepaired: 'Not repaired',
              LESS_15_DAYS: 'Less than 15 days',
              DAYS_16_TO_30: '16 to 30 days',
              DAYS_31_TO_60: '31 to 60 days',
              DAYS_61_TO_90: 'More than 60 days',
            },
          },
        },
        index: {
          assignedActions: 'Assigned Actions to You',
        },
      },
      export: {
        imageUrlOne: 'Record Image Url 1',
        imageUrlTwo: 'Record Image Url 2',
        leakTag: 'Smart Tag/Work Ref.',
        per: 'per ',
      },
    },
    languages: {
      English: 'English',
      Chinese: 'Chinese',
      Spanish: 'Spanish',
      French: 'French',
      German: 'German',
      Norwegian: 'Norwegian',
      Italian: 'Italian',
      Turkish: 'Turkish',
      Japanese: 'Japanese',
    },
  },
};
