import DISPLAY_TYPE from './displayType';

const INTERNAL_FIELD_NAMES = {
  CREATED_AT: 'CREATED_AT',
  RECORD_ID: 'RECORD_ID',
  DOCUMENT_ID: 'DOCUMENT_ID',
  DATE_TIME: 'DATE_TIME',
  COMPANY: 'COMPANY',
  USER: 'USER',
  ASSIGNED_DATE: 'ASSIGNED_DATE',
  ASSIGNED_BY: 'ASSIGNED_BY',
  ASSIGNED_TO: 'ASSIGNED_TO',
  LOSS_PER_YEAR: 'LOSS_PER_YEAR',
  CALCULATION_VERSION: 'CALCULATION_VERSION',
  CREATOR_USER_ID: 'CREATOR_USER_ID',
  COMPLETED_ON: 'COMPLETED_ON',
  TYPE: 'TYPE',
  ROUTEID: 'ROUTEID',
  EQUIPMENTID: 'EQUIPMENTID',
  LAST_MODIFIED: 'LAST_MODIFIED',
  FLOW: 'FLOW',
  DOLLARS_PER_YEAR: 'DOLLARS_PER_YEAR',
  DB_ACTIVE: 'DB_ACTIVE',
  FREQUENCY_LOW_BOUND: 'FREQUENCY_LOW_BOUND',
  FREQUENCY_HIGH_BOUND: 'FREQUENCY_HIGH_BOUND',
  SENSITIVITY: 'SENSITIVITY',
  KWH_PER_YEAR: 'KWH_PER_YEAR',
  ACTION_STATUS_MODIFIED: 'ACTION_STATUS_MODIFIED',
  ROUTE: 'ROUTE',
  ROUTE_LOCATION: 'ROUTE_LOCATION',
  REFERENCE_ID: 'REFERENCE_ID',
  LOCATION: 'LOCATION',
};

export const USER_EDITABLE_FIELD_NAMES_GENERIC = {
  DEVICE_ID: 'DEVICE_ID',
  CLIENT: 'CLIENT',
  FACILITY: 'FACILITY',
  EQUIPMENT: 'EQUIPMENT',
  PROJECT_SURVEY: 'PROJECT_SURVEY',
  ALTITUDE: 'ALTITUDE',
  LEAK_TAG: 'LEAK_TAG',
  REPAIRED_BY: 'REPAIRED_BY',
  REQUIRED_ACTION: 'REQUIRED_ACTION',
  OUTAGE_STATUS: 'OUTAGE_STATUS',
  ACTION_STATUS: 'ACTION_STATUS',
  LEAK_CATEGORY: 'LEAK_CATEGORY',
};

export const USER_EDITABLE_FIELD_NAMES_LEAKS = {
  GAS_COMPONENT: 'GAS_COMPONENT',
  GAS_COST: 'GAS_COST',
  SERVICE_TYPE: 'SERVICE_TYPE',
  DB_PEAK: 'DB_PEAK',
  NOTES: 'NOTES',
  EQUIPMENT_TAG: 'EQUIPMENT_TAG',
  EQUIPMENT_TYPE: 'EQUIPMENT_TYPE',
  LEAK_TYPE: 'LEAK_TYPE',
  LEAK_TEMPERATURE: 'LEAK_TEMPERATURE',
  LEAK_PRESSURE: 'LEAK_PRESSURE',
  DISTANCE: 'DISTANCE',
  AMBIENT_TEMPERATURE: 'AMBIENT_TEMPERATURE',
  COMPRESSOR_BHP: 'COMPRESSOR_BHP',
  COMPRESSOR_FLOW: 'COMPRESSOR_FLOW',
  COMPRESSOR_TYPE: 'COMPRESSOR_TYPE',
  MOTOR_EFFICIENCY: 'MOTOR_EFFICIENCY',
  ENERGY_COST: 'ENERGY_COST',
  HOURS: 'HOURS',
  POWER_LOAD: 'POWER_LOAD',
  CAPACITY: 'CAPACITY',
  SERVICE: 'SERVICE',
  CURRENCY: 'CURRENCY',
};

export const USER_EDITABLE_FIELD_NAMES_ELECTRICAL = {
  REGION: 'REGION',
  LOCATION_TYPE: 'LOCATION_TYPE',
  VOLTAGE: 'VOLTAGE',
  PHASE: 'PHASE',
  FREQUENCY: 'FREQUENCY',
  ELECTRICAL_EQUIPMENT_TAG: 'ELECTRICAL_EQUIPMENT_TAG',
  ELECTRICAL_EQUIPMENT_TYPE: 'ELECTRICAL_EQUIPMENT_TYPE',
  FAULT_TYPE: 'FAULT_TYPE',
  ELECTRICAL_NOTES: 'ELECTRICAL_NOTES',
};

export const RECORD_FIELD_NAMES = {
  ...INTERNAL_FIELD_NAMES,
  ...USER_EDITABLE_FIELD_NAMES_GENERIC,
  ...USER_EDITABLE_FIELD_NAMES_LEAKS,
};

export const ELECTRICAL_RECORD_FIELD_NAMES = {
  ...USER_EDITABLE_FIELD_NAMES_ELECTRICAL,
};

export const RECORD_FIELD_PATHS = {
  CREATED_AT: 'createdAt',
  RECORD_ID: 'documentId',
  DOCUMENT_ID: 'documentId',
  DEVICE_ID: 'deviceId',
  DOLLARS_PER_YEAR: 'liveDataReading.lossPerYear',
  GAS_COMPONENT: 'leakParameters.mainComponent',
  GAS_COST: 'leakParameters.gasCost',
  ENERGY_COST: 'leakParameters.energyCost',
  LEAK_TYPE: 'leakSource.leakType',
  LEAK_TEMPERATURE: 'leakParameters.componentTemperature',
  LEAK_PRESSURE: 'leakParameters.componentPressure',
  LEAK_CATEGORY: 'leakStatus.category',
  AMBIENT_TEMPERATURE: 'leakParameters.ambientTemperature',
  DISTANCE: 'liveDataReading.distance',
  DATE_TIME: 'deviceCreatedAt',
  LAST_MODIFIED: 'lastModified',
  DB_PEAK: 'liveDataReading.dbPeak',
  COMPANY: 'companyName',
  NOTES: 'leakSource.notes',
  USER: 'creator',
  CLIENT: 'leakStatus.client',
  ROUTEID: 'leakStatus.routeId',
  ROUTE: 'leakStatus.routeName',
  ROUTE_LOCATION: 'leakStatus.routeLocation',
  EQUIPMENT_TAG: 'leakSource.equipmentTag',
  EQUIPMENT_TYPE: 'leakSource.equipmentType',
  EQUIPMENT: 'leakStatus.building',
  EQUIPMENTID: 'leakStatus.buildingId',
  FLOW: 'liveDataReading.massFlow',
  PROJECT_SURVEY: 'leakStatus.surveyRecordId',
  OUTAGE_STATUS: 'leakStatus.outageStatus',
  FACILITY: 'leakStatus.facility',
  LOCATION: 'leakStatus.location',
  REQUIRED_ACTION: 'leakStatus.requiredAction',
  ACTION_STATUS: 'leakStatus.actionStatus',
  ACTION_STATUS_MODIFIED: 'leakStatus.actionStatusModified',
  LEAK_TAG: 'leakStatus.leakTag',
  REPAIRED_BY: 'leakStatus.repairedBy',
  SERVICE_TYPE: 'leakSource.serviceType',
  DB_ACTIVE: 'liveDataReading.dbActive',
  REFERENCE_ID: 'leakStatus.surveyRecordId',
  HOURS: 'leakParameters.hoursPerYear',
  COMPRESSOR_FLOW: 'leakParameters.compressorFlow',
  COMPRESSOR_BHP: 'leakParameters.compressorBHP',
  COMPRESSOR_TYPE: 'leakParameters.compressorType',
  MOTOR_EFFICIENCY: 'leakParameters.motorEfficiency',
  POWER_LOAD: 'leakParameters.powerLoad',
  CAPACITY: 'leakParameters.capacity',
  ALTITUDE: 'leakStatus.gpsAltitude',
  FREQUENCY_LOW_BOUND: 'liveDataReading.frequencyLowBound',
  FREQUENCY_HIGH_BOUND: 'liveDataReading.frequencyHighBound',
  SENSITIVITY: 'liveDataReading.sensitivity',
  KWH_PER_YEAR: 'liveDataReading.kwhPerYear',
  IMAGES: 'leakSource.imageData',
  ASSIGNED_BY: 'assignedBy.email',
  ASSIGNED_TO: 'assignedTo.email',
  ASSIGNED_DATE: 'assignedAt',
  FFT: 'liveDataReading.fft',
  CALCULATION_VERSION: 'liveDataReading.calculationVersion',
  CREATOR_USER_ID: 'creatorUserId',
  CURRENCY: 'currency',
  COMPLETED_ON: 'leakStatus.actionStatusModified',
  SAVED_FILTER: 'savedFilter',
  TYPE: 'type',
};

export const ELECTRICAL_RECORD_FIELD_PATHS = {
  REGION: 'electricalParameters.region',
  LOCATION_TYPE: 'electricalParameters.locationType',
  VOLTAGE: 'electricalParameters.voltage',
  PHASE: 'electricalParameters.phase',
  FREQUENCY: 'electricalParameters.frequency',
  ELECTRICAL_EQUIPMENT_TAG: 'electricalSource.equipmentTag',
  ELECTRICAL_EQUIPMENT_TYPE: 'electricalSource.equipmentType',
  FAULT_TYPE: 'electricalSource.faultType',
  ELECTRICAL_NOTES: 'electricalSource.notes',
};

export const DEFAULT_LABELS = {
  CREATED_AT: 'Created At',
  RECORD_ID: 'Record ID Number',
  DOCUMENT_ID: 'Record ID Number',
  DOLLARS_PER_YEAR: 'Loss',
  GAS_COMPONENT: 'Gas/Component',
  LEAK_TYPE: 'Leak Type Profile',
  DATE_TIME: 'Date/Time',
  DB_PEAK: 'Peak dB',
  COMPANY: 'Company',
  NOTES: 'Notes',
  USER: 'User',
  EQUIPMENT_TAG: 'Equipment/Component Tag',
  FLOW: 'Flow (m³/h)',
  EQUIPMENT: 'Unit/Equipment',
  ROUTE: 'Route',
  ROUTE_LOCATION: 'Route Location',
  PROJECT_SURVEY: 'Audit/Survey Record Ref.',
  OUTAGE_STATUS: 'Outage/Shutdown Required?',
  FACILITY: 'Facility/Site',
  ACTION_STATUS: 'Action Status',
  SERVICE_TYPE: 'Service/Type',
  DB_ACTIVE: 'dB Active',
  REFERENCE_ID: 'Project #/Survey ID',
  AMBIENT_TEMPERATURE: 'Ambient Temperature (°C)',
  LEAK_PRESSURE: 'Pressure (kPag)',
  LEAK_TEMPERATURE: 'Gas Temperature (°C)',
  LEAK_CATEGORY: 'Leak Category',
  DISTANCE: 'Distance (m)',
  GAS_COST: 'Cost of Gas ($/m³)',
  COMPRESSOR_FLOW: 'm³/h per BHP',
  DEVICE_ID: 'Device ID',
  COMPRESSOR_BHP: 'Compressor BHP',
  COMPRESSOR_TYPE: 'Compressor Type',
  MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
  ENERGY_COST: 'Cost of Energy ($/kWh)',
  HOURS: 'Hours Running/Year',
  LOCATION: 'Leak Location',
  LAST_MODIFIED: 'Last Saved Date',
  POWER_LOAD: '% Full Load Power',
  CLIENT: 'Client',
  SERVICE: 'Service',
  CAPACITY: '% Capacity',
  EQUIPMENT_TYPE: 'Equipment/Component Type',
  LEAK_TAG: 'Leak Tag #/Work Ref. #',
  REPAIRED_BY: 'Repaired By',
  ALTITUDE: 'Altitude (m)',
  REQUIRED_ACTION: 'Required Action',
  FREQUENCY_LOW_BOUND: 'Frequency (Low)',
  FREQUENCY_HIGH_BOUND: 'Frequency (High)',
  SENSITIVITY: 'Sensitivity',
  KWH_PER_YEAR: 'kWh/year',
  ASSIGNED_BY: 'Assigned By',
  ASSIGNED_TO: 'Assigned To',
  ASSIGNED_DATE: 'Assignment Date',
  LOSS_PER_YEAR: 'Loss (per year)',
  ASSIGNED: 'Assigned',
  CREATOR_USER_ID: 'User ID',
  CURRENCY: 'Currency',
  COMPLETED_ON: 'Completed On',
  IMAGES: 'Leak Source Images',
  ACTION_STATUS_MODIFIED: 'Action Status Modified',
  TYPE: 'Type',
};

export const ELECTRICAL_LABELS = {
  REGION: 'Region',
  LOCATION_TYPE: 'Location Type',
  VOLTAGE: 'Voltage',
  PHASE: 'Phase',
  FREQUENCY: 'Frequency',
  ELECTRICAL_EQUIPMENT_TAG: 'Equipment/Component',
  ELECTRICAL_EQUIPMENT_TYPE: 'Equipment/Component Type',
  FAULT_TYPE: 'Fault Type',
  ELECTRICAL_NOTES: 'Notes',
};

export const IMPERIAL_LABELS = {
  FLOW: 'Flow (cfm)',
  AMBIENT_TEMPERATURE: 'Ambient Temperature (°F)',
  LEAK_PRESSURE: 'Pressure (psi)',
  LEAK_TEMPERATURE: 'Gas Temperature (°F)',
  DISTANCE: 'Distance (ft)',
  GAS_COST: 'Cost of Gas ($/ft³)',
  COMPRESSOR_FLOW: 'cfm per BHP',
  ALTITUDE: 'Altitude (ft)',
};

export const RECORD_COLUMN_TYPES = {
  DOLLARS_PER_YEAR: DISPLAY_TYPE.ROUNDED_NUMBER,
  DATE_TIME: DISPLAY_TYPE.DATE,
  LAST_MODIFIED: DISPLAY_TYPE.DATE,
  FLOW: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPRESSOR_FLOW: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPRESSOR_BHP: DISPLAY_TYPE.ROUNDED_NUMBER,
  POWER_LOAD: DISPLAY_TYPE.ROUNDED_NUMBER,
  OUTAGE_STATUS: DISPLAY_TYPE.BOOLEAN,
  DB_ACTIVE: DISPLAY_TYPE.ROUNDED_NUMBER,
  DB_PEAK: DISPLAY_TYPE.ROUNDED_NUMBER,
  ENERGY_COST: DISPLAY_TYPE.ROUNDED_NUMBER,
  AMBIENT_TEMPERATURE: DISPLAY_TYPE.ROUNDED_NUMBER,
  LEAK_PRESSURE: DISPLAY_TYPE.ROUNDED_NUMBER,
  DISTANCE: DISPLAY_TYPE.ROUNDED_NUMBER,
  KWH_PER_YEAR: DISPLAY_TYPE.ROUNDED_NUMBER,
  ASSIGNED_DATE: DISPLAY_TYPE.DATE,
  ALTITUDE: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPLETED_ON: DISPLAY_TYPE.DATE,
  CREATED_AT: DISPLAY_TYPE.DATE,
};

export const RECORD_COLUMN_WIDTHS = {
  GAS_COMPONENT: 150,
  DB: 150,
  NOTES: 300,
  EQUIPMENT_TAG: 250,
};

export const UNIT_FIELDS = [
  RECORD_FIELD_NAMES.AMBIENT_TEMPERATURE,
  RECORD_FIELD_NAMES.LEAK_PRESSURE,
  RECORD_FIELD_NAMES.LEAK_TEMPERATURE,
  RECORD_FIELD_NAMES.DISTANCE,
  RECORD_FIELD_NAMES.GAS_COST,
  RECORD_FIELD_NAMES.COMPRESSOR_FLOW,
  RECORD_FIELD_NAMES.COMPRESSOR_BHP,
  RECORD_FIELD_NAMES.FLOW,
];
