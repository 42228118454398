import { useMutation, useQueryClient } from 'react-query';
import queries from '../queries';

const useSavedFilterCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name, filters }) => {
      return queries.filters.createSavedFilter.query(name, filters);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.filters.leaks.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useSavedFilterDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name }) => {
      return queries.filters.deleteSavedFilter.query(name);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.filters.leaks.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useSavedFilterCreate,
  useSavedFilterDelete,
};
