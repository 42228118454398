import DISPLAY_TYPE from 'src/constants/displayType';

export const ENERGY_FIELD_NAMES = {
  UTILITY_PROVIDER: 'UTILITY_PROVIDER',
  ENERGY_COST: 'ENERGY_COST',
  ENERGY_UNIT: 'ENERGY_UNIT',
  REBATE_COST: 'REBATE_COST',
  REBATE_UNIT: 'REBATE_UNIT',
  ENERGY_SOURCES: 'ENERGY_SOURCES',
  FE_EFFICIENCY: 'FE_EFFICIENCY',
  FE_HOURS_OPERATION: 'FE_HOURS_OPERATION',
  FE_ENERGY_COST: 'FE_ENERGY_COST',
};

export const ENERGY_FIELD_PATHS = {
  UTILITY_PROVIDER: 'utilProvider.name',
  ENERGY_COST: 'utilProvider.energyCost',
  ENERGY_UNIT: 'utilProvider.energyUnit',
  REBATE_COST: 'utilProvider.rebateCost',
  REBATE_UNIT: 'utilProvider.rebateUnit',
  ENERGY_SOURCES: 'utilProvider.energySources',
  FE_EFFICIENCY: 'energyUseAndConsumption.fixed.efficiency',
  FE_HOURS_OPERATION: 'energyUseAndConsumption.fixed.hoursOfOperation',
  FE_ENERGY_COST: 'energyUseAndConsumption.fixed.energyCost',
};

export const LABELS = {
  UTILITY_PROVIDER: 'Utility Provider',
  ENERGY_COST: '$/kWh',
  ENERGY_UNIT: 'Unit',
  REBATE_COST: '$/kWh',
  REBATE_UNIT: 'Unit',
  ENERGY_SOURCES: 'Energy Sources',
  FE_EFFICIENCY: 'Efficiency (kW/cfm)',
  FE_HOURS_OPERATION: 'Hours of Operation',
  FE_ENERGY_COST: '$/kWh',
  KWH_PER_FLOW: 'kWh/cfm',
  COST_PER_FLOW: '$/cfm',
};

export const CALCULATION_LABELS = {
  FIXED: 'Fixed',
  USDOE: 'US DoE',
  NREL: 'NREL',
};
