import api from '../helpers';

const mapper = ({ data }) => {
  return Object.entries(data).reduce((acc, [key, details]) => {
    // filter out "id" filters
    if (details.type === 'id') return acc;

    return { ...acc, [key]: details };
  }, {});
};

export default {
  leaks: {
    key: 'fetchLeakFilters',
    query: (newFilter, appliedFilters) => {
      const applied = appliedFilters ?? {};
      return api.get('/api/filters/leaks', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          filter: newFilter,
          applied,
        },
      });
    },
    mapper,
  },
  createSavedFilter: {
    key: 'createSavedFilter',
    query: (name, filters) => {
      return api.post('/api/filters/leaks', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { name, filters },
      });
    },
  },
  deleteSavedFilter: {
    key: 'deleteSavedFilter',
    query: (name) => {
      return api.delete(`/api/filters/leaks/${name}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  leaksNonEmptyTags: {
    key: 'fetchLeakFiltersNonEmptyTags',
    query: (newFilter, appliedFilters) => {
      const applied = appliedFilters ?? {};
      return api.get('/api/filters/leaks?applied[leakStatus.leakTag]=$notEmpty', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          filter: newFilter,
          applied,
        },
      });
    },
    mapper,
  },
  assignments: {
    key: 'fetchAssignmentFilters',
    query: () => {
      return api.get('/api/filters/assignments', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    mapper,
  },
};
