export default {
  translation: {
    commonUsedWords: {
      login: '登录',
      email: '电子邮件地址',
      password: '密码',
      emailIsRequired: '需要电子邮件',
      mustBeAValidEmail: '必须是有效的电子邮件',
      deviceSerialNumber: '设备序列号',
      next: '下一步',
      success: '成功',
      submit: '提交',
      firstName: '名字',
      firstNameIsRequired: '需要名字',
      lastName: '姓氏',
      lastNameIsRequired: '需要姓氏',
      save: '保存',
      delete: '删除',
      undoChanges: '撤销更改',
      cancel: '取消',
      loading: '加载中...',
      yes: '是',
      no: '否',
      clear: '清除',
      confirm: '确认',
      noOption: '没有选项',
      openText: '打开',
      edit: '编辑',
      logout: '注销',
      latitude: '纬度',
      longitude: '经度',
      ok: '好',
      okay: '好的',
      done: '完成',
      comingSoon: '即将推出...',
      reset: '重置',
      attention: '注意',
      note: '备注',
    },
    api: {
      auth: {
        fieldsAreMissing: '缺少必填字段',
        userNotFound: '未找到用户',
        missingRefreshToken: '缺少刷新令牌',
        cannotRefreshToken: '无法刷新令牌',
      },
      helpers: {
        error: '错误',
        invalidHTTPMethod: '无效的 HTTP 方法',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: '确认电子邮件地址',
        confirmEmailIsRequired: '需要确认的电子邮件',
        emailNotMatched: '电子邮件条目不匹配',
      },
      confirmPassword: {
        confirmPassword: '确认密码',
        confirmPasswordIsRequired: '需要确认密码',
        newPasswordIsRequired: '需要新密码',
        showPassword: '显示密码',
        passwordLength: '密码至少需6个字符',
        passwordContainSpaces: '密码不能包含空格',
        passwordNotMatch: '密码条目不匹配',
        newPassword: '新密码',
      },
      confirmModal: {
        areYouSure: '您确定吗？',
      },
      appliedFilters: {
        between: '介于 {{firstValue}} 和 {{secondValue}} 之间',
        bottom: '底部 {{primaryValue}}%',
        top: '顶部 {{primaryValue}}%',
        activeFilters: '活跃过滤器',
        active: '活跃',
        closed: '关闭',
      },
      editTable: {
        editTable: '编辑表格',
        selectDeselect: '选择/取消选择表格值',
        resetToDefault: '重置为默认',
      },
      errorBoundary: {
        oops: '哎呀！发生了意外的情况。',
        tryAgain: '请再试一次！如果问题仍然存在，请联系您的管理员。',
        refresh: '刷新',
      },
      noResultFound: {
        noResultFoundTryAgain: '抱歉，未找到结果。请再试一次。',
      },
      permissionGuard: {
        invalidPermission: '无效的权限结构，数组中应有3个元素',
      },
      preview: {
        findInView: '在视图中查找',
        showDetails: '显示详情',
        closeEsc: '关闭 (Esc)',
        prevLeftArrow: '上一个 (左箭头)',
        nextRightArrow: '下一个 (右箭头)',
        proceedWithoutSaving: '在不保存的情况下导航',
        continueEditing: '继续编辑',
        unsavedChanges: {
          partTwo: '您有 ',
          partThree: '未保存的更改',
          partFour: '。在不保存的情况下导航将丢弃所有更改。您确定吗？',
        },
      },
      printRecord: {
        title: '记录',
        date: '日期:',
        location: '位置',
        leakDetails: '详情',
        leakParameters: '参数',
        energyLoss: '能源损失',
        leakSourceEquipment: '源设备',
        notes: '备注',
        leakEventStatus: '状态',
        requiredAction: '所需的操作',
        leakSourceImage: '源图片',
        smartLeakTag: '智能标签',
        lossPerYear: '损失 ({{currency}}/年)',
        costOfEnergy: '能源成本 ({{currency}}/千瓦时)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: '分配已被删除',
          deleteAssignmentError: '无法删除分配。请稍后再试',
          currentlyAssigned: '当前分配的操作',
          repairedBy: '修复者',
          text: '您即将删除此分配。此操作无法撤销',
        },
        actionSaveDate: '最后保存',
      },
      visibleColumnsPopper: {
        tableValues: '选择/取消选择表格值',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: '等于',
        LESS_THAN: '小于',
        GREATER_THAN: '大于',
        LESS_THAN_OR_EQUAL_TO: '小于或等于',
        GREATER_THAN_OR_EQUAL_TO: '大于或等于',
        BETWEEN_A_AND_Y: '之间',
        TOP_PERCENT: '顶部 "x"%',
        BOTTOM_PERCENT: '底部 "x"%',
      },
      recordFields: {
        CREATED_AT: '创建日期',
        RECORD_ID: '记录编号',
        DOCUMENT_ID: '记录编号',
        DOLLARS_PER_YEAR: '损失',
        GAS_COMPONENT: '气体/组件',
        LEAK_TYPE: '类型简介',
        DATE_TIME: '日期/时间',
        DB_PEAK: '峰值 dB',
        COMPANY: '公司',
        NOTES: '备注',
        USER: '用户',
        EQUIPMENT_TAG: '设备/组件标签',
        FLOW: '流量 (m³/h)',
        EQUIPMENT: '单元/设备',
        ROUTE: '路线',
        ROUTE_LOCATION: '路线位置',
        PROJECT_SURVEY: '审计/调查记录编号',
        OUTAGE_STATUS: '停机/停工是否需要?',
        FACILITY: '设施/地点',
        ACTION_STATUS: '行动状态',
        SERVICE_TYPE: '服务/类型',
        DB_ACTIVE: 'dB 活跃',
        REFERENCE_ID: '项目编号/调查 ID',
        AMBIENT_TEMPERATURE: '环境温度 (°C)',
        LEAK_PRESSURE: '压力 (kPag)',
        LEAK_TEMPERATURE: '气体温度 (°C)',
        LEAK_CATEGORY: '类别',
        DISTANCE: '距离 (m)',
        GAS_COST: '气体成本 ($/m³)',
        COMPRESSOR_FLOW: 'm³/h 每 BHP',
        DEVICE_ID: '设备编号',
        COMPRESSOR_BHP: '压缩机 BHP',
        COMPRESSOR_TYPE: '压缩机类型',
        MOTOR_EFFICIENCY: '电机效率 (%)',
        ENERGY_COST: '能源成本（$/千瓦时）',
        HOURS: '小时运行/年',
        LOCATION: '位置',
        LAST_MODIFIED: '最后保存日期',
        POWER_LOAD: '% 满载功率',
        CLIENT: '客户',
        SERVICE: '服务',
        CAPACITY: '% 满载',
        EQUIPMENT_TYPE: '设备/组件类型',
        LEAK_TAG: '智能标签编号/工作编号',
        REPAIRED_BY: '维修人员',
        ALTITUDE: '海拔高度 (m)',
        REQUIRED_ACTION: '需要的行动',
        FREQUENCY_LOW_BOUND: '频率 (低)',
        FREQUENCY_HIGH_BOUND: '频率 (高)',
        SENSITIVITY: '敏感性',
        KWH_PER_YEAR: 'kWh/年',
        ASSIGNED_BY: '指派人',
        ASSIGNED_TO: '指派给',
        ASSIGNED_DATE: '指派日期',
        LOSS_PER_YEAR: '每年的损失',
        ASSIGNED: '已指派',
        CREATOR_USER_ID: '用户 ID',
        CURRENCY: '货币',
        COMPLETED_ON: '完成日期',
        IMAGES: '源图片',
        ACTION_STATUS_MODIFIED: '行动状态已修改',
        TYPE: '类型',
      },
      electricalRecordFields: {
        REGION: '区域',
        LOCATION_TYPE: '位置类型',
        VOLTAGE: '电压',
        PHASE: '相位',
        FREQUENCY: '频率',
        ELECTRICAL_EQUIPMENT_TAG: '设备/组件',
        ELECTRICAL_EQUIPMENT_TYPE: '设备/组件类型',
        FAULT_TYPE: '故障类型',
        ELECTRICAL_NOTES: '备注',
      },
      IMPERIAL_LABELS: {
        FLOW: '流量 (cfm)',
        AMBIENT_TEMPERATURE: '环境温度 (°F)',
        LEAK_PRESSURE: '压力 (psi)',
        LEAK_TEMPERATURE: '气体温度 (°F)',
        DISTANCE: '距离 (ft)',
        GAS_COST: '燃气成本 ($/ft³)',
        COMPRESSOR_FLOW: 'cfm 每马力',
        ALTITUDE: '海拔 (ft)',
      },
      userFields: {
        EMAIL: '电子邮件',
        ROLE: '角色',
        DEVICE_ID: '设备ID',
        SUBSCRIPTION_RENEWAL_DATE: '订阅续费日期',
        DATE_TIME: '创建时间',
        ACTIVE: '状态',
        SOFTWARE_VERSION: '软件版本',
        FIRMWARE_VERSION: '固件版本',
        FIRST_NAME: '名字',
        LAST_NAME: '姓氏',
        COMPANY_SLUG: '公司',
        COMPANY_NAME: '公司',
      },
      auditFields: {
        documentId: '记录ID号',
        companyName: '公司名称',
        auditorName: '审计员姓名',
        auditorEmail: '审计员电子邮件',
        createdAt: '创建于',
        updatedAt: '更新于',
        comment: '评论',
        isLocked: '审计锁定',
      },
      deviceFields: {
        DEVICE_ID: '设备ID',
        ACTIVE: '状态',
        REGISTERED_ON: '激活于',
        EVENT_COUNT: '记录',
        UNIQUE_COMPANIES: '公司',
        LAST_USED_AT: '最后使用时间',
        LAST_USED_BY_NAME: '最后使用名称',
        LAST_USED_BY_EMAIL: '最后使用电子邮件',
        LOCATION: '最后使用位置',
        FIRMWARE_VERSION: '固件版本',
      },
      statsFields: {
        labels: {
          TIMELINE: '时间线',
          CRITERIA: '标准',
        },
        timeline: {
          LAST_3_MONTHS: '最近3个月',
          LAST_6_MONTHS: '最近6个月',
          LAST_12_MONTHS: '最近12个月',
          YEAR_TO_DATE: '年初至今',
          ALL_TIME: '全部时间',
        },
        criteria: {
          LOSS: '损失',
          FLOW: '流量',
          KWH: '千瓦时',
        },
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: '发现的泄漏数',
          NO_OF_LEAKS_REPAIRED: '修复的泄漏数',
          PERCENT_LEAKS_REPAIRED: '修复的泄漏百分比',
          VOL_FLOW_FOUND: '发现的流量 (m³/h)',
          VOL_FLOW_REPAIRED: '修复的流量 (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '修复的流量百分比',
          VALUE_FOUND: '发现的价值',
          VALUE_REPAIRED: '修复的价值',
          ENERGY_SAVING_OPPORTUNITY_FOUND: '节能机会 (已发现) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: '节能机会百分比 (已发现 vs 计算或测量)',
          ENERGY_SAVING_REALIZED_FIXED: '已实现的节能 (已修复) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: '已实现的节能百分比 (已修复 vs 计算或测量)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: '系统控制压力降低 (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: '发现的流量 (cfm)',
          VOL_FLOW_REPAIRED: '修复的流量 (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: '节能机会 (已发现) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: '已实现的节能 (已修复) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: '系统控制压力降低 (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: '绩效指标',
          ENPI_VALUE: 'EnPI 值',
          ENB_VALUE: 'EnB 值',
          SUCCESS_THRESHOLD: '成功阈值 (%)',
        },
      },
      assignedAction: {
        assignedTo: '指派给',
        assignedBy: '指派者',
        assignedDate: '指派日期',
        requestReassign: '请求重新分配',
        actionStatus: '操作状态',
        partsNeeded: '需要的零件',
        productionOutage: '请求停机',
        timeToRepair: '维修时间',
        repairCost: '维修成本',
        closeOutRef: '关闭参考',
        'assignmentRecord.documentId': '记录ID',
        assignmentId: '分配ID',
        owner: '所有者',
        companyName: '公司',
        assignmentActive: '分配状态',
        'assignmentRecord.leakStatus.building': '单元/设备',
        'assignmentRecord.leakStatus.facility': '设施',
        'assignmentRecord.leakStatus.routeName': '路线',
        'assignmentRecord.leakStatus.leakTag': '智能标签#/#工单参考号',
        'assignmentRecord.currency': '货币',
      },
      AAR_FIELD_LABEL: {
        NOTES: '已采取的措施',
        CLOSE_OUT_REF: '关闭措施参考号',
        PARTS_NEEDED: '需要新零件/组件',
        PRODUCTION_OUTAGE: '生产中断',
        REPAIR_COST: '成本 ($)',
        REQUEST_REASSIGN: '请求重新分配',
        ACTION_STATUS: '措施状态',
        TIME_TO_REPAIR: '维修所需时间（分钟）',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: '按泄漏类别划分的流量',
          LOSS_BY_LEAK: '按泄漏类别划分的损失',
          TAGS_BY_LEAK: '按泄漏类别划分的标签',
          AVG_FLOW_BY_LEAK: '按泄漏类别划分的平均流量',
          SAVING_OPT_BY_REPAIR: '按修复时间划分的机会和节省',
          FLOW_REPAIR_TIME: '按修复时间划分的流量',
          LOSS_REPAIR_TIME: '按修复时间划分的损失',
          FLOW_REPAIR_PROG: '按修复进度划分的流量',
          TOTAL_REPAIR_PROG: '按修复进度划分的损失',
          FLOW_BY_EQUIP_COMP: '按设备/组件划分的流量',
          LOSS_BY_EQUIP_COMP: '按设备/组件划分的损失',
          TAGS_BY_EQUIP_COMP: '按设备/组件划分的标签',
        },
        leakCategoryLabels: {
          LOW: '低',
          MEDIUM: '中',
          HIGH: '高',
        },
        actionStatus: {
          OPEN: '打开',
          COMPLETE: '完成',
        },
        equipmentTypes: {
          CONNECTOR: '连接器',
          CONTROL_VALVE: '控制阀',
          HOSE: '软管',
          TUBE_FITTING: '管接头',
          METER: '仪表',
          OPEN_ENDED_LINE: '开口管线',
          PRESSURE_RELIEF_VALVE: '压力释放阀',
          PUMP_SEAL: '泵密封',
          REGULATOR: '调节器',
          VALVE: '阀门',
          LEVEL_CONTROLLER: '液位控制器',
          POSITIONER: '定位器',
          PRESSURE_CONTROLLER: '压力控制器',
          TRANSDUCER: '传感器',
          GENERIC_PNEUMATIC_INSTRUMENT: '通用气动仪表',
          GENERIC_RECIPROCATING_COMPRESSOR: '通用往复式压缩机',
          GENERIC_CENTRIFUGAL_COMPRESSOR: '通用离心压缩机',
        },
        mainGasValues: {
          AIR: '空气',
          METHANE: '甲烷 (CH4)',
          CARBON_DIOXIDE: '二氧化碳 (CO2)',
          HYDROGEN: '氢气 (H2)',
          HELIUM: '氦气 (He)',
          NITROGEN: '氮气 (N2)',
          REFRIGERANT: '制冷剂 (HFCs)',
          ARGON: '氩气 (Ar)',
          OXYGEN: '氧气 (O)',
          WATER: '水（蒸汽）',
        },
        leakTypeValues: {
          DEFAULT: '未选择',
          HOLE: '孔',
          CRACK: '裂缝',
          FITTING: '接头',
          FLANGE: '法兰',
          'GLAND/SEAL': '密封圈/密封',
          THREAD: '螺纹',
          'TUBE/HOSE': '管/软管',
        },
        serviceValues: {
          COMPRESSED_AIR: '压缩空气',
          VACUUM: '真空',
          STEAM: '蒸汽',
          PROCESS_GAS: '工艺气体',
          LIQUID: '液体',
        },
        compressorTypesValues: {
          ROTARY_SCREW: '螺杆式',
          RECIPROCATING: '往复式',
          SCROLL: '涡旋式',
          CENTRIFUGAL: '离心式',
        },
        regionValues: {
          NORTH_AMERICA: '北美',
          OUTSIDE_NORTH_AMERICA: '北美以外',
        },
        locationTypeValues: {
          RESIDENTIAL: '住宅',
          COMMERCIAL: '商业',
          INDUSTRIAL: '工业',
          MEDIUM_VOLTAGE: '中压',
          HIGH_VOLTAGE: '高压',
        },
        phaseValues: {
          SINGLE_PHASE: '单相',
          THREE_PHASE: '三相',
        },
        faultValues: {
          KNICKS_OR_VOIDS_IN_INSULATION: '绝缘中的划痕或空隙',
          CORONA: '电晕',
          TREEING: '树状放电',
          TRACKING: '爬电',
          ARCING: '电弧',
          'DIRTY_POWER/POWER_DISTORTION': '脏电/电力失真',
          MECHANICAL_LOOSENESS: '机械松动',
          DELAMINATION_OF_WINDINGS: '绕组分层',
          LOOSE_PANEL: '松动的面板',
          VIBRATING_NAMEPLATE: '振动的铭牌',
          ANTAGONISTIC_ULTRASOUND: '对抗性超声波',
          OTHER: '其他',
        },
        electricEquipmentTypeValues: {
          MCC: '电机控制设备 (MCC)',
          'TRANSFORMER,_DRY': '干式变压器',
          'TRANSFORMER,_OIL': '油式变压器',
          SWITCH_GEAR: '开关设备',
          PANEL: '面板',
          BUSDUCT: '母线槽',
          CABLE: '电缆',
          CONNECTOR: '连接器',
          BREAKER: '断路器',
          BUSHING: '套管',
          TRANSMISSION_LINE: '输电线',
          'TRANSFORMER,_POLE': '杆式变压器',
          BATTERY: '电池',
          BATTERY_BANK: '电池组',
          TERMINALS: '端子',
          'TRANSFORMER,_STEP_UP': '升压变压器',
          'TRANSFORMER,_STEP_DOWN': '降压变压器',
          DISTRIBUTION_PANEL: '配电面板',
          BREAKER_PANEL: '断路器面板',
          RELAYS: '继电器',
          CAPACITORS: '电容器',
          REACTORS: '电抗器',
          UPS: '不间断电源 (UPS)',
          GROUNDING: '接地',
          POWER_METERS: '电表',
          CURRENT_TRANSFORMERS: '电流互感器',
          POTENTIAL_TRANSFORMERS: '电压互感器',
          SURGE_PROTECTION: '浪涌保护',
          CABLE_TRAYS: '电缆桥架',
          EMERGENCY_LIGHTING: '紧急照明',
          CONTACTORS: '接触器',
          INVERTERS: '逆变器',
          SOLAR_PANELS: '太阳能电池板',
          BATTERY_STORAGE_SYSTEMS: '电池储能系统',
        },
        recordTypes: {
          leak: '泄漏',
          electrical: '电气',
        },
        labels: {
          LOW: '低',
          MEDIUM: '中',
          HIGH: '高',
          EQUIPMENT: '设备',
          SAVINGS: '节省',
          OPPORTUNITY: '机会',
          LOSS: '损失',
          REPAIRED: '已修复',
          UNREPAIRED: '未修复',
          LESS_15_DAYS: '少于15天',
          DAYS_16_TO_30: '15到30天',
          DAYS_31_TO_60: '31到60天',
          DAYS_61_TO_90: '超过60天',
          LESS_THAN_15_DAYS: '修复时间 < 15 天',
          '16_TO_30_DAYS': '修复时间 16-30 天',
          '31_TO_60_DAYS': '修复时间 31-60 天',
          MORE_THAN_60_DAYS: '修复时间 61-90 天',
          AIR: '空气',
          METHANE: '甲烷 (CH4)',
          CARBON_DIOXIDE: '二氧化碳 (CO2)',
          HYDROGEN: '氢气 (H2)',
          HELIUM: '氦气 (He)',
          NITROGEN: '氮气 (N2)',
          REFRIGERANT: '制冷剂 (HFCs)',
          ARGON: '氩气 (Ar)',
          OXYGEN: '氧气 (O)',
          WATER: '水（蒸汽）',
          DEFAULT: '未选择',
          HOLE: '孔',
          CRACK: '裂缝',
          FITTING: '接头',
          FLANGE: '法兰',
          'GLAND/SEAL': '密封圈/密封',
          THREAD: '螺纹',
          'TUBE/HOSE': '管/软管',
          COMPRESSED_AIR: '压缩空气',
          VACUUM: '真空',
          STEAM: '蒸汽',
          PROCESS_GAS: '工艺气体',
          LIQUID: '液体',
          ROTARY_SCREW: '螺杆式',
          RECIPROCATING: '往复式',
          SCROLL: '涡旋式',
          CENTRIFUGAL: '离心式',
          CONNECTOR: '连接器',
          CONTROL_VALVE: '控制阀',
          HOSE: '软管',
          TUBE_FITTING: '管接头',
          METER: '仪表',
          OPEN_ENDED_LINE: '开口管线',
          PRESSURE_RELIEF_VALVE: '压力释放阀',
          PUMP_SEAL: '泵密封',
          REGULATOR: '调节器',
          VALVE: '阀门',
          LEVEL_CONTROLLER: '液位控制器',
          POSITIONER: '定位器',
          PRESSURE_CONTROLLER: '压力控制器',
          TRANSDUCER: '传感器',
          GENERIC_PNEUMATIC_INSTRUMENT: '通用气动仪表',
          GENERIC_RECIPROCATING_COMPRESSOR: '通用往复式压缩机',
          GENERIC_CENTRIFUGAL_COMPRESSOR: '通用离心压缩机',
        },
        leakRecordPDF: '记录 • 导出于 {{date}}',
        leakTag: '智能标签 • 导出于 {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: '压缩机标签',
          COMPRESSOR_MAKE: '压缩机品牌',
          COMPRESSOR_MODEL: '压缩机型号',
          COMPRESSOR_TYPE: '压缩机类型',
          NAMEPLATE_HP: '铭牌功率 (HP)',
          NAMEPLATE_CAPACITY: '铭牌容量 (m³/h)',
          MOTOR_EFFICIENCY: '电机效率 (%)',
          MOTOR_VOLTAGE: '电机电压',
          FULL_LOAD_CURRENT: '满载电流 (安培)',
          FULL_LOAD_INPUT_POWER: '满载输入功率 (kW)',
          RATED_PRESSURE: '额定压力 (kPag)',
          PRESSURE_SETPOINT: '压力设定点 (kPag)',
          CONTROL: '控制',
          TOTAL_AIR_STORAGE_SIZE: '总空气存储容量 (升)',
          IS_ACTIVE: '压缩机状态 (开启/关闭)',
          OPERATION_TYPE: '修整 / 基本负载',
          PERCENT_LOAD: '% 负载',
          COMPRESSOR_SETPOINT_SEU: '压缩机设定点 (kPag)',
          COMPRESSOR_FLOW_SEU: '压缩机流量 (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: '系统压力设定点 (kPag)',
          SYSTEM_MEASURED_FLOW: '系统测量流量 (m³/h)',
          DATE_TIME_RUNNING_STATE: '运行状态的日期和时间',
          NOTES: '备注',
          NAMEPLATE: '压缩机铭牌',
          DATASHEET: '压缩机数据表',
          BHP: 'BHP',
          OPERATING_HOURS: '运行小时',
          LOADED_PERCENT_TIME_RUNNING: '% 运行时间',
          LOADED_PERCENT_FULL_LOAD_BHP: '% 满载 BHP (容量)',
          UNLOADED_PERCENT_TIME_RUNNING: '% 运行时间',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% 满载 BHP (容量)',
          ENERGY_COST: '总电费 ($)',
          ANNUAL_ENERGY_COST: '年度电费 ($)',
          ANNUAL_ENERGY_KWH: '年度电能 (kWh)',
          COST_FULLY_LOADED: '满载成本 ($)',
          COST_UNLOADED: '卸载成本 ($)',
          KWH_PER_YEAR_LOADED: '满载时每年 kWh',
          KWH_PER_YEAR_UNLOADED: '卸载时每年 kWh',
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: '铭牌容量 (cfm)',
          FULL_LOAD_INPUT_POWER: '满载输入功率 (BTU/h)',
          RATED_PRESSURE: '额定压力 (psi)',
          PRESSURE_SETPOINT: '压力设定点 (psi)',
          TOTAL_AIR_STORAGE_SIZE: '总空气存储容量 (加仑)',
          COMPRESSOR_SETPOINT_SEU: '压缩机设定点 (psi)',
          COMPRESSOR_FLOW_SEU: '压缩机流量 (cfm)',
          SYSTEM_PRESSURE_SETPOINT: '系统压力设定点 (psi)',
          SYSTEM_MEASURED_FLOW: '系统测量流量 (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: '公用事业提供商',
          ENERGY_COST: '$/千瓦时',
          ENERGY_UNIT: '单位',
          REBATE_COST: '$/千瓦时',
          REBATE_UNIT: '单位',
          ENERGY_SOURCES: '能源来源',
          FE_HOURS_OPERATION: '操作时间',
          FE_ENERGY_COST: '能源成本 ($/千瓦时)',
          FE_EFFICIENCY: '效率 (千瓦/立方米/小时)',
          KWH_PER_FLOW: '千瓦时/立方米/小时',
          COST_PER_FLOW: '$/立方米/小时',
        },
        labelsImperial: {
          FE_EFFICIENCY: '效率 (千瓦/100立方英尺/分钟)',
          KWH_PER_FLOW: '千瓦时/立方英尺/分钟',
          COST_PER_FLOW: '$/立方英尺/分钟',
        },
      },
    },
    hooks: {
      useError: {
        message: '请求验证错误。请检查表单字段',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: '注册设备',
          help: '帮助',
          metric: '公制',
          imperial: '英制',
          downloadApk: '下载 APK',
          downloadOta: '下载文件',
        },
        otaDialog: {
          title: '下载文件',
          downloadButton: '下载',
          serialNumberLabel: '设备序列号',
          serialNumberRequired: '需要序列号',
          fileDownloaded: '文件 {{fileName}} 已成功下载',
          errorFetchingFile: '获取文件时出错。请稍后再试',
          selectSerialNumber: '选择设备序列号',
        },
        banners: {
          leakRecordsList: {
            dialogOne: '您正在使用基本计划。',
            dialogTwo: '点击',
            dialogThree: '这里',
            dialogFour: '购买新的管理员许可或联系 ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable: '我们遇到了一些技术问题。我们的服务可能会受到影响。对不起给您带来不便。',
          downForMaintenance: '我们的服务正在维护中。请稍后再试。',
          tagsManagement: {
            dialogOne: '购买更多',
            dialogTwo: 'PROSARIS 智能标签',
            dialogThree: '这里！',
          },
        },
        mobileNav: {
          index: {
            menu: '菜单',
            editProfile: '编辑个人资料',
            logout: '注销',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: '清除全部',
            readAll: '全部标记为已读',
            noNotifications: '无通知',
          },
        },
        index: {
          dashboard: '仪表盘',
          records: '记录',
          actions: '操作',
          company: '公司',
          tags: '标签',
        },
        loggedInAs: '作为登录',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: '无法通过字段 {{field}} 确定标签',
        cannontDeterminByLabel: '无法通过标签 {{label}} 确定字段',
        active: '活跃',
        closed: '关闭',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: '仪表盘',
        records: '记录',
        actions: '操作',
        company: '公司',
        tags: '标签',
        redirecting: '正在重定向您...',
      },
      account: {
        index: {
          updatePassword: '密码更新成功。',
          accountDeletedSuccess: '账户{{user}}已成功删除',
          accountDeletedError: '目前无法删除账户。请稍后再试。',
          authenticationError: '用户身份验证失败',
          personalDetails: '个人详情',
          currentPasswordIsRequired: '需要当前密码',
          username: '用户名',
          changePassword: '更改密码',
          currentPassword: '当前密码',
          profilePicture: '个人头像',
          dangerZone: '危险区域',
          integrations: '集成',
          sosShipments: 'SOS 库存',
          sosSyncMessage:
            '该过程通过 SOS 库存中的最新数据触发 Prosaris 的 SOS 订单处理。虽然该过程通常会自动管理每 4 小时通过计划任务，但使用此按钮允许您手动触发该过程。',
          sosSyncSuccess: 'SOS 同步已成功触发',
          triggerSosSync: '触发 SOS 同步',
          hubspot: 'HubSpot',
          hubspotSyncMessage:
            '该过程通过平台的最新数据立即推送到 HubSpot。该同步每小时自动进行一次通过计划任务，但单击按钮可随时手动触发同步。',
          hubspotSyncSuccess: 'HubSpot 同步已成功触发',
          triggerHubspotSync: '触发 HubSpot 同步',
          deleteAccountTitle: '删除账户',
          deleteAccountMessage: '该账户将不再可用，所有个人信息将被永久删除。',
          alertTitle: '请确认删除账户',
          deleteDialog: {
            dialogTwo: '删除您的账户将永久从我们的数据库中删除您的所有个人信息。',
            dialogThree: '此操作无法撤销。',
            dialogFour: '请输入您的密码以继续。',
          },
        },
        toolBar: {
          cacheClearedSuccess: '缓存清除成功！',
          editProfile: '编辑个人资料',
          clearCache: '清除缓存',
          alertTitle: '请确认清除应用缓存',
          deleteAll: '全部删除',
          accordionDialog: {
            dialogOne: '这将删除以下页面的所有存储设置：',
            dialogTwo: '图表、图表过滤器、时间范围',
            dialogThree: '过滤器、排序、列、预览',
            dialogFour: '过滤器、列',
            dialogFive: '选定公司',
            dialogSix: '过滤器、列',
          },
          deleteSelective: '选择性删除',
          selectiveDialog: '请选择您要删除的存储设置：',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: '时间范围',
            CHART_FILTERS: '图表过滤器',
            CHARTS: '图表',
          },
          Records: {
            HIGHLIGHTED: '预览',
            COLUMNS: '列',
            FILTERS: '过滤器',
            SORT_COLUMN: '排序',
          },
          Actions: {
            FILTERS: '过滤器',
            COLUMNS: '列',
          },
          Company: {
            SELECTED: '选定公司',
          },
          Tags: {
            FILTERS: '过滤器',
            COLUMNS: '列',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: '公司',
          'leakStatus.building': '单元/设备',
          'leakStatus.facility': '设施',
          'leakStatus.leakTag': '智能标签编号/工作参考编号',
        },
        tableBody: {
          noAssignmentsToDisplay: '没有任务显示',
        },
        filtersCommonWords: {
          recordsFilter: '记录过滤器',
          column: '列',
          selectAColumn: '选择一列...',
          addSearchTerm: '添加搜索词...',
          filtersArentAvailable: '过滤器不可用',
          value: '值',
          exportCSV: '导出CSV',
          show: '显示',
          loadMoreRecords: '加载更多记录',
          selectedAllRecords: '选择了所有记录 ({{totalNum}})',
          selectedFromRecords: '从{{totalNum}}条记录中选择了{{selectedNum}}条记录',
          reset: '重置',
        },
        invalidColumn: '无效的列{{column}}',
        assignedActionRecords: '分配的操作记录',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: '忘记密码了吗？',
          successMessage: '我们已向您发送了确认邮件',
        },
        authSuccess: {
          thanks: '谢谢！',
          backToLogin: '返回Prosaris登录',
        },
        loginView: {
          forgotPass: '忘记密码？',
          createAccount: '创建账户',
          passwordIsRequired: '需要密码',
          resetPassword: '重置密码',
          contactSupport: '请联系 support@prosaris.ca',
          incorrectEmailOrPassword: '邮箱或密码错误',
          resetPassDialog: {
            dialogOne: '在继续之前，您需要重置密码。',
            dialogTwo: '已向您发送了包含说明的密码重置电子邮件。',
          },
          ok: '好的',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: '密码已成功重置',
          enterNewPassword: '请在下方输入您的新密码。',
          passwordChanged: '您的密码已更改。',
        },
        createCompanyView: {
          createCompany: '创建公司',
          companyName: '公司名称',
          successCreatingCompany: '您的公司已创建。',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: '加载泄漏数据时发生意外错误',
          calculatingError: '计算时发生意外错误',
          dbMeasured: '测得的分贝',
          calculate: '计算',
          calculating: '正在计算...',
          outflowCoefficient: '出流系数',
          kwhPerYear: 'Kwh/年',
        },
      },
      companies: {
        commonUsedWords: {
          assignedAdminLicenses: '分配的管理员许可',
          company: '公司',
          totalAccounts: '总账户',
          email: '电子邮件',
          name: '名称',
          loadCompanyUsersError: '加载公司用户失败。请稍后再试',
          activated: '已激活',
          deactivated: '已停用',
          activating: '激活中',
          deactivating: '停用中',
          deactivate: '停用',
          activate: '激活',
          set: '设置',
          youAreGoingTo: '您将要',
          areYouSure: '您确定吗？',
          companyNameIsRequired: '需要公司名称',
          editCompany: '编辑公司',
          createCompany: '创建公司',
          viewHistory: '查看历史',
          onEssentialPlan: '您使用的是基本计划。请联系 support@prosaris.ca',
          exportCSV: '导出CSV',
        },
        groupPerformance: {
          title: '组表现',
          normalizeData: '规范数据',
          selectTimeline: '选择时间线',
          selectCriteria: '选择标准',
        },
        charts: {
          chart: {
            criteria: {
              KWH: '总千瓦时/年',
              LOSS: '总损失（$/年）',
              FLOW: '总质量流量',
            },
            noDataAvailable: '无可用数据',
            topNCompanies: '前{{numCompanies}}家公司',
          },
        },
        accounts: {
          accountDialog: {
            companyIsRequired: '需要公司',
            roleIsRequired: '需要角色',
            createAccountSuccessMessage: '成功创建账户',
            emailTakenErrorMessage: '该电子邮件的账户已存在于系统中',
            errorWhileCreatingAccountMessage: '创建账户时出错。请稍后再试。',
            changeAccountSuccessMessage: '成功更新账户',
            errorWhileChangingAccountMessage: '更新账户时出错。请再试一次。',
            editAccount: '编辑账户',
            createAccount: '创建账户',
            role: '角色',
          },
          changeOwner: {
            changeOwnerMessage: '您是否也要更改与此账户相关的泄漏记录的所有者？',
            no: '不，保持当前账户不变',
          },
          contentCell: {
            loadAccountRolesError: '加载账户角色信息失败。请稍后再试',
            changeRoleSuccessMessage: '账户{{email}}有一个新角色',
            changeRoleErrorMessage: '更新账户{{email}}的角色时出错。请稍后再试',
            editUser: '编辑用户',
            adminSetPassword: '管理员设置密码',
            adminSetSubRenewalDate: '管理员设置订阅续费日期',
            resetPassword: '重置密码',
            user: '用户',
            deleteUser: '删除用户',
          },
          index: {
            noUsersToDisplay: '没有用户显示',
            changeStatusSuccessMessage: '用户{{isActive}}成功',
            changeStatusErrorMessage: ' 用户{{isActive}}时出错。请再试一次',
            changeOwnerSuccessMessage: '记录已转移给新所有者',
            changeOwnerErrorMessage: '无法将记录转移给新所有者。请稍后再试',
            deleteAccountMutationSuccessMessage: '账户{{email}}已成功删除',
            deleteAccountMutationErrorMessage: ' 目前无法删除{{email}}账户。请稍后再试',
            resetAccountPassWordSuccessMessage: '密码重置邮件已成功发送。',
            users: '用户',
            noneAvailable: '无可用',
            of: '的',
            contactSupport: '请联系 support@prosaris.ca 获取有关公司管理员许可的更多信息',
            findAccount: '查找账户',
            placeholder: '输入姓名或电子邮件...',
            tooltip: '您需要至少一个公司管理员许可才能邀请用户',
            invite: '邀请',
            alertDialog: {
              messageOne: '请确认用户的重置密码请求',
              messageTwo: '请确认账户状态更改',
              messageThree: '请确认账户删除',
            },
            sendAPasswordResetEmail: '您将为以下账户发送密码重置邮件：',
            confirmMessage: '您要继续吗？',
            followingAccount: '以下账户：',
            delete: '删除',
            userCreatedDialogTitle: '用户密码重置链接发送成功',
            userCreatedDialogDescription: '已向用户的电子邮件地址发送了密码重置链接。您也可以复制以下链接。',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: '账户密码更新成功',
            changedUserPasswordErrorMessage: '更新账户时出错。请再试一次。',
            setNewPassword: '设置新密码',
            accountPasswordUpdated: '账户密码已更新',
            accountPasswordUpdatedDescription: '账户密码已成功更新。您可以点击下面的按钮复制新密码。',
          },
          adminSetSubRenewalDate: {
            title: '设置订阅续费日期',
            subRenewalDate: '订阅续费日期',
            changedSubRenewalDateSuccessMessage: '成功更新订阅续费日期',
            changedSubRenewalDateErrorMessage: '更新账户时出错，请重试。',
          },
          setPassword: {
            automatic: '自动生成强密码',
            manual: '手动创建密码',
            copyLinkInstruction: '您可以在下一步查看并复制密码',
          },
          userCreatedDialog: {
            title: '用户创建成功',
            description: '您的新用户现在可以开始使用 Prosaris 仪表板。用户还将通过电子邮件收到一个重置密码的链接。',
            passwordCopied: '密码已复制到剪贴板',
            passwordResetLinkCopied: '重置密码链接已复制到剪贴板',
            copyPassword: '复制密码',
            copyPasswordResetLink: '复制重置密码链接',
          },
          useRoleOptions: {
            notAvailable: '(不可用)',
            adminSeatTaken: '(管理员席位已被占用)',
            allSeatsAreTaken: '(所有{{numSeats}}席位已被占用)',
            adminRolesTitle: '管理员角色',
            roles: {
              superAdmin: '管理员',
              companyAdmin: '公司（管理员）',
              superCompanyAdmin: '企业（管理员）',
              companyRead: '公司（只读）',
              superAdminRead: 'Prosaris 管理员（只读）',
              integrator: '集成商',
              auditor: '审计员',
              user: '用户',
              essential: 'Prosaris 基本',
            },
            rolesDescriptions: {
              superAdmin: '允许对多个公司拥有完全控制权。',
              companyAdmin: '允许查看、编辑和分配公司记录。',
              superCompanyAdmin: '允许对公司记录和用户拥有完全控制权。',
              companyRead: '允许以只读模式查看公司记录。',
              superAdminRead: '允许对所有公司进行只读访问。',
              integrator: '允许第三方集成管理公司和账户。',
              auditor: '允许审核泄漏记录。',
              user: '允许创建/更新/删除自己的记录。',
              essential: '具有有限功能的角色。',
            },
            nonAdminRolesTitle: '非管理员角色',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: '加载审计失败。请稍后再试。',
            audits: '审计',
            noAuditsToDisplay: '没有审计可显示',
            findByAuditorEmail: '按审计员电子邮件查找',
            placeholder: '输入审计员电子邮件...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: '需要设施名称',
              hoursYearlyIsRequired: '需要每年的总小时数',
              totalHoursYearly: '每年的总小时数不能超过8760',
              invalidPhoneNumber: '无效的电话号码',
              createErrorMessage: '小时 x 班次不能超过24小时',
              loadFacilityErrorMessage: '加载设施失败。请稍后再试。',
              createFacilitySuccessMessage: '成功创建设施',
              createFacilityErrorMessage: '创建设施时出错。请稍后再试。',
              changeFacilitySuccessMessage: '设施更新成功',
              changeFacilityErrorMessage: '更新设施时出错。请再试一次。',
              facilityName: '设施名称',
              abbreviation: '缩写',
              address: '地址',
              enterALocation: '输入位置',
              tooltip: '用于能源管理活动，也是报告批准人',
              energyMgtOwner: '能源管理负责人',
              title: '职位',
              phone: '电话',
              tooltipTwo: '用于解决方案续订和一般账户问题',
              accountOwner: '账户所有者',
              manufacturingArea: '生产区',
              size: '大小',
              unit: '单位',
              hoursOfOperation: '营业时间',
              auditFrequency: '审计频率',
            },
            operationHours: {
              daysPerWeek: '天/周',
              shiftsPerDay: '班次/天',
              hoursPerShift: '小时/班次',
              weeksPerYear: '52周/年',
              totalHoursYearly: '总计 = 每年小时数',
            },
            constants: {
              constant: '恒定',
              monthly: '每月',
              threeMonth: '3个月',
              fourMonth: '4个月',
              sixMonth: '6个月',
              nineMonth: '9个月',
              annual: '每年',
              sqft: '平方英尺 [SQFT]',
              sqm: '平方米 [SQM]',
            },
          },
          index: {
            users: '用户',
            devices: '设备',
            facility: '设施',
            energy: '能源',
            seu: '关键用能设备 - 组件',
            enpi: '能效指标/基准',
            routes: '路线',
            audit: '审计',
            comingSoon: '即将推出...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: '加载 EnPIs 失败。请稍后再试',
            createEnpisSuccessMessage: '成功创建 EnPIs',
            createEnpisErrorMessage: '创建 EnPIs 时出错。请稍后再试。',
            changeEnpisSuccessMessage: '成功更新 EnPIs',
            changeEnpisErrorMessage: '更新 EnPIs 时出错。请再试一次。',
            showHistory: '显示历史',
          },
          changeHistory: {
            bulkOperation: '作为批量操作',
            details: '详情',
            created: '创建',
            updated: '更新',
            componentTypes: {
              enpis: '能效指标',
            },
            history: '历史',
            noHistory: '无历史记录',
          },
        },
        energy: {
          index: {
            utilProvider: '公用事业供应商',
            energyUse: '能源使用与消费类型',
            error: {
              loadFacility: '加载设施失败。请稍后再试',
              loadEnergy: '加载能源数据失败。请稍后再试',
              loadUtilProviderLogo: '加载公用事业供应商标志失败。请稍后再试',
              createEnergy: '创建能源数据失败。请稍后再试',
              createUtilProviderLogo: '上传公用事业供应商标志失败。请稍后再试',
              updateEnergy: '更新能源数据失败。请稍后再试',
              deleteUtilProviderLogo: '删除公用事业供应商标志失败。请稍后再试',
            },
            success: {
              createEnergy: '成功创建能源数据',
              updateEnergy: '成功更新能源数据',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: '能源成本',
              incentiveRate: '激励率',
              energySources: '能源来源',
              per: '每',
              validations: {
                utilProviderIsRequired: '公用事业供应商为必填项',
                costOfEnergyIsRequired: '能源成本为必填项',
                costOfEnergyMustBeNumber: '能源成本必须为数字',
                costOfEnergyMustBeGreaterThanZero: '能源成本必须大于 0',
                costOfRebateMustBeAtLeatZero: '回扣费用必须至少为 0',
                costOfRebateMustBeNumber: '回扣费用必须是数字',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: '文件大小必须小于 1 MB',
                preview: '预览图像失败',
              },
              logo: '标志',
              addLogo: '添加标志',
              removeLogo: '删除标志',
            },
            EnergySources: {
              energySource: '能源来源',
              percentage: '百分比',
              selectEnergySource: '选择能源来源',
              addEnergySource: '添加能源来源',
              totalPercentage: '总百分比',
              mustBeEqualTo100: '（必须等于 100%）',
              constants: {
                energySources: {
                  NATURAL_GAS: '天然气',
                  FUEL_OIL: '燃油',
                  DIESEL: '柴油',
                  GASOLINE: '汽油',
                  PROPANE: '丙烷',
                  COAL: '煤',
                  WIND: '风能',
                  GEOTHERMAL: '地热',
                  BIOMASS: '生物质',
                  STEAM: '蒸汽',
                  COMPRESSED_AIR: '压缩空气',
                  CHILLED_WATER: '冷水',
                  NUCLEAR: '核能',
                  HYDRO: '水力',
                  SOLAR: '太阳能',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: '当前计算方法',
              currentCalcApproachMessage: '除非用户已覆盖，否则此计算方法默认应用于所有“能源成本”字段。',
              alertTitle: '重置计算方法',
              alertMessageOne: '此操作将停止在所有“能源成本”字段中使用当前计算方法。',
              alertMessageTwo: '请注意，此更改将不影响任何现有记录。',
              alertMessageThree: '您确定要继续重置吗？',
            },
            ReportType: {
              calcApproach: '计算方法',
              constants: {
                calculations: {
                  FIXED: '固定效率',
                  USDOE: '美国能源部',
                  NREL: '国家可再生能源实验室第22章',
                },
              },
              setAsCurrent: '设为当前',
              setAsCurrentMessage: '此计算方法将用于所有“能源成本”字段。',
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: '效率必须大于或等于 0',
                  efficiencyMustBeNumber: '效率必须为数字',
                  operationHoursMustBeGreaterThanZero: '运行时间必须大于或等于 0',
                  operationHoursMustBeLessThan8760: '运行时间必须小于 8760',
                  operationHoursMustBeNumber: '运行时间必须为数字',
                },
              },
              UsDoe: {
                loaded: '加载',
                unloaded: '卸载',
                calcSummary: '计算摘要',
                currentOperatingSystems: '当前操作系统',
                noCompsFoundAddCompOne: '未找到压缩机。从上面的',
                noCompsFoundAddCompTwo: '标签添加压缩机。',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: '上传铭牌时出错。请稍后再试。',
            dataSheetError: '上传数据表时出错。请稍后再试。',
            createSuccess: '压缩机{{tag}}已创建',
            createError: '创建压缩机失败。请稍后再试。',
            createCompressor: '创建压缩机',
          },
          compressorFile: {
            tip: '添加文件',
            removeFile: '移除文件',
            removeImage: '移除图片',
            title: '请确认删除压缩机{{prop}}',
            proceed: '您要继续吗？',
          },
          compressorForm: {
            compTagIsReq: '压缩机标签是必需的',
            hpMustBeNum: '铭牌马力必须是数字',
            capMustBeNum: '铭牌容量必须是数字',
            motorEffMustBeNum: '电动机效率必须是数字',
            motorVoltMustBeNum: '电动机电压必须是数字',
            loadMustBeNum: '满载电流必须是数字',
            loadInputMustBeNum: '满载输入功率必须是数字',
            pressureMustBeNum: '额定压力必须是数字',
            setpointMustBeNum: '压力设定点必须是数字',
            airMustBeNum: '总空气存储大小必须是数字',
            addImage: '添加图片',
            addFile: '添加文件',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: '螺杆式',
              RECIPROCATING: '往复式',
              CENTRIFUGAL: '离心式',
            },
            rotaryScrew: {
              loadUnloadOilFree: '负载/卸载 – 无油',
              loadUnload1Gal: '负载/卸载 (1 加仑/CFM)',
              loadUnload3Gal: '负载/卸载 (3 加仑/CFM)',
              loadUnload5Gal: '负载/卸载 (5 加仑/CFM)',
              loadUnload10Gal: '负载/卸载 (10 加仑/CFM)',
              ivmWithBlowdown: '进气阀调节（无排放）',
              ivmWithoutBlowdown: '进气阀调节（带排放）',
              variableDisplacement: '可变排量',
              vsdWithUnloading: '变速驱动带卸载',
              vsdWithStopping: '变速驱动带停止',
            },
            reciprocating: {
              reciprocatingOnOffControl: '开/关控制',
              reciprocatingLoadUnload: '负载/卸载',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV 带排放',
              centrifugalInletIbv: 'IGV 带排放',
            },
          },
          contentCell: {
            min: '% 必须至少为 0',
            max: '% 不能超过 100',
            max150: '% 不能超过 150',
            typeError: '负载必须是有效的百分比',
            setpointMustBeNum: '压缩机设定值必须是数字',
            flowMustBeNum: '压缩机流量必须是数字',
            pressureSetpointMustBeNum: '系统压力设定值必须是数字',
            measuredFlowMustBeNum: '系统测量流量必须是数字',
            dateTime: '选择日期和时间',
            active: '活跃',
            off: '关闭',
            trim: '调整',
            baseLoad: '基负荷',
          },
          index: {
            error: '加载压缩机失败。请稍后再试',
            nameplateError: '加载铭牌失败。请稍后再试',
            dataSheetError: '加载数据表失败。请稍后再试',
            changeCompSuccess: '压缩机更新成功',
            changeCompError: '更新压缩机时出错。请再试一次。',
            deleteCompSuccess: '压缩机删除成功',
            deleteCompError: '删除压缩机时出错。请再试一次。',
            createCompNameplateSuccess: '压缩机铭牌上传成功',
            createCompNameplateError: '上传铭牌时出错。请稍后再试。',
            deleteCompNameplateSuccess: '压缩机铭牌删除成功',
            deleteCompNameplateError: '删除压缩机铭牌时出错。请再试一次。',
            createCompDataSheetSuccess: '压缩机数据表上传成功',
            createCompDataSheetError: '上传数据表时出错。请稍后再试。',
            deleteCompDataSheetSuccess: '压缩机数据表删除成功',
            deleteCompDataSheetError: '删除压缩机数据表时出错。请再试一次。',
            changeCompanyCompSuccess: '系统数据更新成功',
            changeCompanyCompError: '更新系统数据时出错。请再试一次。',
            compDesignData: '压缩机设计数据',
            comp: '压缩机：',
            addComp: '添加压缩机',
            editCompPermission: '您需要管理员许可证才能编辑压缩机。请联系',
            selectOption: '选择一个选项',
            undoChanges: '撤销更改',
            selectComp: '从上方选择一个压缩机。',
            noCompsFound: '未找到压缩机。',
            compRunningState: '压缩机运行状态',
            noCompsFoundAddComp: '未找到压缩机。从上面的部分添加一个压缩机。',
            title: '请确认删除压缩机',
            areYouSure: '您确定要',
            compressor: '压缩机',
            delete: '删除',
          },
        },
        devices: {
          contentCell: {
            device: '设备',
            usedByMultipleCompanies: '被多家公司使用',
          },
          index: {
            noDevicesToDisplay: '没有设备显示',
            loadingDevicesErrorMessage: '加载公司设备失败。请稍后再试。',
            changeStatusSuccessMessage: '设备{{isActive}}成功',
            changeStatusErrorMessage: '设备{{isActive}}时出错。请再试一次。',
            devices: '设备',
            findDevice: '查找设备',
            placeholder: '输入设备ID...',
            activatedOn: '激活于',
            title: '请确认设备状态更改',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}}已成功删除',
          deleteCompanyErrorMessage: '删除{{companyName}}失败。请稍后再试。',
          needAdminLicense: '您需要管理员许可才能访问此功能。请联系 support@prosaris.ca',
          deleteChildCompanyFirst: '无法删除具有子公司或账户的公司。请先删除所有子公司和账户。',
          editSettings: '编辑设置',
          createASubCompany: '为{{companyName}}创建子公司',
          deleteCompany: '删除公司',
          cannotCreateChildCompanyDialog: {
            messageOne: '您已达到公司帐户可以创建的最大公司数量。请联系',
            messageTwo: '了解更多详情。',
          },
          deleteCompanyDialog: {
            title: '请确认删除公司',
            delete: '删除',
            deleting: '删除',
            permanentlyDeleted: '将永久从我们的数据库中删除。',
            cannotBeUndone: '此操作无法撤销。',
          },
        },
        companyDialog: {
          industryIsRequired: '需要行业',
          createLogoErroerMessage: '上传图片时出错。请稍后再试。',
          deleteLogoErrorMessage: '删除图片时出错。请稍后再试。',
          createCompanySuccessMessage: '公司{{name}}已创建',
          createCompanyErrorMessage: '创建公司失败。请稍后再试。',
          changeCompanySuccessMessage: '公司{{name}}已更新',
          changeCompanyErrorMessage: '更改公司参数失败。请稍后再试。',
          language: '语言',
          industry: '行业',
          country: '国家',
          currency: '货币',
        },
        companyLogo: {
          setImageErrorMessage: '文件大小必须小于1 MB',
          logo: '徽标',
          add: '添加',
          removeLogo: '移除徽标',
          addLogo: '添加徽标',
        },
        index: {
          loadCompaniesErrorMessage: '加载公司列表失败。请稍后再试。',
          changeCompaniesSectionWidth: '更改公司部分宽度',
        },
        sidebar: {
          filterCompanies: '筛选公司',
          placeholder: '输入公司名称',
          myCompany: '我的公司',
          childCompanies: '子公司',
        },
        routes: {
          index: {
            noFacility: '您没有定义任何设施',
            createAFacility: '创建一个设施以开始规划路线',
            goToCreateAFacility: '前往创建设施',
            createAFacilityTooltip: '您需要先创建一个设施才能开始规划路线。',
            save: '保存',
            reset: '重置',
            routePlans: '路线计划',
            loading: '加载中...',
            noRoutesAdded: '没有添加路线。',
            buildARouteTooltip: '使用路线构建器构建一条路线。',
            createFloorplanSuccess: '成功！平面图已上传！',
            createRouteSuccess: '成功！路线已创建！',
          },
          routeBuilder: {
            title: '路线构建器',
            missingFields: 'Excel文件中缺少字段',
            manuallyBuildARoute: '手动构建新路线计划',
            manualInstructions: '您可以手动构建路线或上传Excel文件。',
            facility: '设施',
            route: '路线',
            enterRoute: '输入路线',
            addFloorplan: '添加平面图',
            floorplanInstructionsTooltip: '添加一张平面图的图片以帮助您构建路线。保存后，路线将叠加在平面图上。',
            change: '更改',
            location: '位置',
            enterLocation: '输入位置',
            equipment: '设备',
            enterEquipment: '输入设备',
            criticality: '重要性',
            enterCriticality: '输入重要性',
            description: '描述',
            enterDescription: '输入描述',
            addEquipment: '添加设备',
            addRoute: '添加路线',
            downloadTemplate: '下载模板',
          },
          excelReader: {
            loadFromFile: '从文件加载',
            instructionsTooltip: '您可以通过上传一个Excel文件来构建路线，其中每个工作表代表设施中的不同位置。每个位置可以有多个路线。',
          },
          graph: {
            controls: {
              addFloorplan: '添加平面图',
              toggleLabels: '切换标签',
              addWaypoint: '添加路径点',
              addEquipment: '添加设备',
              swapEquipment: '交换设备',
              showLeakStats: '显示记录统计',
              save: '保存',
              delete: '删除',
            },
            routeStats: {
              title: '路线统计',
              totalDollarLossPerYear: '每年总美元损失：',
              totalKwhLossPerYear: '每年总千瓦时损失：',
              totalLeakCount: '记录总数：',
              totalActiveOpen: '总活动开启：',
              totalActiveClosed: '总活动关闭：',
              noStatsAvailable: '没有可用统计数据：',
              close: '关闭',
            },
            nodeDetails: {
              edit: '编辑',
              name: '名称',
              description: '描述',
              criticality: '重要性',
              equipmentInformation: '设备信息',
              thisIsAWaypoint: '这是一个路径点',
              activeOpenLeakCount: '活动未关闭记录数：',
              noActiveLeaksDetected: '未检测到活动泄漏',
              close: '关闭',
              nothingToSeeHere: '这里没有什么好看的',
              seeLeakRecords: '查看记录',
            },
            swapEquipment: {
              title: '交换设备',
              selectEquipment: '选择要交换的设备：',
              selectFirst: '选择第一个设备',
              selectSecond: '选择第二个设备',
              swap: '交换',
              cancel: '取消',
            },
            addNode: {
              title: '添加',
              waypoint: '路径点',
              equipment: '设备',
              afterWhich: '在哪个设备之后？',
              selectPreceding: '选择前面的设备：',
              selectEquipment: '选择设备',
              name: '名称',
              description: '描述',
              criticality: '重要性',
              add: '添加',
              cancel: '取消',
            },
            save: '保存',
            delete: '删除',
            successRouteUpdated: '路线更新成功',
            errorRouteUpdated: '更新路线时出错，请稍后再试。',
            successRouteDeleted: '路线删除成功',
            errorRouteDeleted: '删除路线时出错，请稍后再试。',
            successFloorplanUpdated: '平面图更新成功',
            errorFloorplanUpdated: '更新平面图时出错，请稍后再试。',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: '分配的管理员许可证',
            of: '的',
            edit: '编辑',
          },
          essentialMode: {
            disable: '禁用',
            enable: '启用',
            essential: '基础模式：',
            enabled: '已启用',
            disabled: '已禁用',
            moveCompany: '将公司{{company}}移动到基础模式',
            affectedCompanies: '受影响的公司数量',
            affectedAccounts: '受影响的账户数量',
            archivedLeak: '存档的泄漏事件记录数量',
            applyingChanges:
              '应用这些更改后，账户将获得“基础”角色。用户将能够在仪表板和移动应用程序上查看{{quota}}条最新的泄漏事件记录。其他记录将被存档。',
            essentialLabel: '更改子公司的基础模式',
            turnOffEssential: '为公司{{company}}关闭基础模式',
            byApplyingChanges: '应用这些更改后，所有账户将获得“用户”角色。用户将能够查看他们的所有记录。所有记录将被取消存档。',
          },
          index: '设置',
        },
        index: {
          loadCompanyError: '无法加载公司信息。请稍后再试',
          companySlug: '公司别名',
          notSelected: '未选择',
          country: '国家',
          accounts: '账户',
          currency: '货币',
          adminLicenses: '管理员许可证',
          totalCustomer: '树形结构中的客户总账户数',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: '注册您的设备',
          toolTip: '请在您的注册卡或盒子上找到序列号。',
          serialNumberIsRequired: '需要序列号',
          oops: '哎呀！',
          deviceNotFound: '未找到设备序列号',
          deviceFoundNdAssociatedCompany: '您的设备已找到，并已与一家公司关联。',
          deviceFound: ' 您的设备已找到。',
          goToLogin: '前往登录',
          contactSupport: '请联系支持',
        },
        deviceErrorCodes: {
          'B13:001': '未找到设备序列号',
          'B13:002': '设备已激活',
          'B13:003': '管理员邮件未发送',
          'B13:004': '未找到公司',
          'B13:005': ' 公司无效',
          'B13:006': '类型无效',
        },
        registerAccountView: {
          registerAccount: '创建Prosaris账户',
          termsMustBeAccepted: '必须接受条款和条件',
          saleType012Success: '您可以登录开始使用您的设备。',
          saleType3Success: '您已被添加为基本用户。已通知您的公司管理员。',
          saleType4Success: {
            companyPlatformFound: '公司平台许可证已找到。',
            dialogOne: '该设备与新的公司平台许可证相关联。',
            dialogTwo: '您的公司可能已经存在于我们的系统中。',
            dialogThree: '如果是这样，请联系您的管理员。',
          },
          termsAndConditions: {
            dialogOne: '我同意Prosaris的',
            dialogTwo: '服务条款',
            dialogThree:
              '并确认我有足够的时间阅读并理解其包含关于我使用Prosaris软件的重要条款，例如限制Prosaris的责任以及如何处理我的数据。',
          },
          acceptTerms: '接受条款',
          registerAccountErrorCodes: {
            'B02:001': '注册码无效',
            'B02:002': '用户公司无效',
            'B02:003': '用户角色无效',
            'B02:004': '邮箱已被占用',
            'B02:005': 'E邮箱已被占用',
            'B02:006': '无法为您的账户重置密码',
            'B02:007': '用户公司无效',
            'B02:008': '缺少个人照片',
            'B02:009': '需要重置账户。已开始处理',
            'B02:010': '账户已被停用',
            'B02:011': '个人照片格式无效',
            'B02:012': '设备已注册到公司',
            'B02:013': '序列号未定义',
            'B02:014': '类型无效',
            'B02:015': '重置密码链接已过期',
            'B02:016': '无法为此公司创建账户',
            'B02:017': '角色无效',
            'B02:018': '角色无效',
            'B02:019': '用户公司无效',
            'B02:020': '用户角色无效',
            'B02:021': '用户不属于此公司',
          },
        },
        devicesView: {
          serialNumbersIsReq: '需要序列号',
          failedToCopy: '失败的序列号已复制到剪贴板',
          invalidFormat: '一个或多个设备的格式无效。请检查并重试。',
          devices: '注册您的设备',
          deviceSerialNumber: '设备序列号',
          note: '将每个序列号输入在新行上或用逗号分隔。',
          title: '请在注册卡或包装盒上找到序列号。',
          register: '注册',
          deviceRegistrationStatus: '设备注册状态',
          success: '成功',
          noDeviceFound: '未找到设备。',
          failed: '失败',
          copyFailed: '复制失败的序列号到剪贴板',
        },
      },
      errors: {
        notFoundView: {
          title: '您要查找的页面不在这里',
          description: '您可能尝试了某些可疑的路径，或者是误入此页面。无论是哪种情况，请尝试使用导航。',
        },
        accountNotFound: {
          title: '找不到账户',
          description: '请再试一次！如果问题仍然存在，可能是您的账户目前未激活或尚未由管理员初始化。请联系您的管理员。',
          logout: '登出',
        },
      },
      integration: {
        index: {
          loggingInError: '登录Prosaris时出错。',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: '无可用图片',
          activeActionRecord: {
            updateAssignmentSuccess: '已更新分配的措施。',
            updateAssignmentError: '更新分配的措施时出错。',
            deleteImageSuccess: '分配的图片已被删除。',
            deleteImageError: '删除分配的措施图片时出错。',
            confirmMarkAction: '您真的想将此措施标记为完成吗？',
            confirmDeleteImage: '您真的想删除此图片吗？',
          },
          index: '加载记录的分配措施时出错。',
        },
        audio: {
          noAudioAvailable: '没有可用的音频数据',
          errorDownloadingAudio: '下载音频时出错。请稍后再试。',
          playPause: '播放 / 暂停',
          generateRegion: '生成区域',
          removeLastRegion: '删除最后一个区域',
          generateMarker: '生成标记',
          removeLastMarker: '删除最后一个标记',
          toggleTimeline: '切换时间轴',
          download: '下载',
        },
        audit: {
          updateAuditSuccess: '审计更新成功',
          updateAuditError: '保存评论时出错。请稍后再试。',
          createAuditSuccess: '审计创建成功',
          createAuditError: '创建审计时出错。请稍后再试。',
          deleteAuditSuccess: '审计已成功删除',
          deleteAuditError: '删除审计失败。请稍后再试。',
          noComment: '无评论',
          reviewComments: '审核评论',
          reviewedBy: '审核者：',
          auditDate: '审计日期：',
          deleteAudit: '删除审计',
          reviewedNoComments: '审核无评论',
          auditComments: '审计评论',
          saveComment: '保存评论',
          discardChanges: '放弃更改',
        },
        changeHistory: {
          created: '创建',
          updated: '更新',
          deleted: '删除',
          transferred: '转移',
          leak: '记录',
          images: '图片',
          audit: '审计',
          audio: '音频',
          history: '历史记录',
          noHistory: '无历史记录',
          bulk: '作为批量操作',
          details: '详情',
          unset: '未设置',
        },
        fft: {
          fft: 'FFT（快速傅里叶变换）',
          fftAvg: 'FFT平均值',
          notApplicable: '不适用',
          chartLayers: '图表层',
          avg: '平均值',
          all: '全部',
          playing: '播放中...',
          showTimeSeries: '显示时间序列',
        },
        form: {
          updateLeakSuccess: '更新成功。',
          updateLeakError: '更新泄漏时出错。请稍后再试。',
          alertTitle: {
            partOne: '记录位于',
            partTwo: '只读',
            partThree: '模式。',
          },
          alertDialog: {
            dialogOne: '然而，以下属性可以修改：',
            dialogTwo: '操作状态',
          },
          GPSLocation: 'GPS位置',
          completedOn: '完成于：',
          lastSaved: '最后保存：',
        },
        images: {
          removeSuccess: '图片删除成功。',
          removeError: '无法删除泄漏记录图片。请稍后再试。',
          text: '您确定要删除这张图片吗？',
        },
        index: {
          title: '记录',
          loadingError: '加载泄漏记录时出错。请再试一次。',
          unsavedMessage: '按下“确定”即表示您确认所有未保存的更改将被删除',
          notAnAuditor: '您不是审计员',
          anEssential: '您使用的是基础计划',
          showHistory: '显示历史记录',
        },
        massFlow: {
          recalculating: '根据泄漏参数设置重新计算流量/损失...',
          warning: '输入值已更改或计算已过期，点击“重新计算”以刷新流量和损失值',
          calculatingError: '计算质量流量时出错。请再试一次。',
          noValue: '无值',
          calculatingSuccess: '流量/损失已重新计算',
          recalculate: '重新计算',
          calculatorVersion: '计算版本：',
          noteDialog: {
            dialogOne: ' 更改泄漏参数设置将更新流量/损失计算。检查',
            dialogTwo: '计算见解',
            dialogThree: '页面以获取更多信息',
          },
        },
        unsavedWarningDialog: {
          recordChanged: '记录已更改',
          close: '关闭',
          unsavedChanges: '此记录有未保存的更改。请考虑保存这些更改或将其完全删除。',
          discardChanges: '放弃更改',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: '需要设备ID',
          dbPeakIsRequired: '需要测量的峰值DB',
          dbMin: '测量的峰值DB必须大于或等于0',
          dbMax: '测量的峰值DB必须小于或等于95',
          dbError: '测量的峰值DB必须是数字',
          imageUploadError: '上传图片时出错。请稍后再试。',
          createLeakSuccess: '成功添加记录',
          createLeakError: '创建记录时出错，请稍后再试。',
          basicInfo: '基本信息',
          flowInput: '流量输入',
          mainGas: '主要气体/组件',
          selectComponent: '选择组件',
          selectLeakType: '选择泄漏类型',
          mightBeSomethingHere: '尚不确定',
          energyLoss: '能量损失',
          selectCompressorType: '选择压缩机类型',
          noRoutes: '未找到路线',
          noEquipment: '未找到设备',
          noLocations: '未找到位置',
          energyCost: '能源成本',
          audioRecording: '音频录制',
          leakSourceEquipment: '来源',
          selectComponentType: '选择组件类型',
          selectService: '选择服务',
          locationAndUser: '位置和用户',
          leakEventStatus: '事件状态',
          low: '低',
          medium: '中',
          high: '高',
          open: '打开',
          complete: '完成',
          reset: '重置',
        },
        formElectrical: {
          systemVoltages: '系统电压',
          source: '来源',
        },
        images: {
          leakSourceImageCapture: '来源图像捕获',
          add: '添加',
        },
        index: {
          title: '创建记录',
        },
      },
      leakRecordsList: {
        filters: {
          from: '从',
          to: '到',
          qualifier: '限定符',
          savedFilters: '已保存筛选器',
          filter: '筛选器',
          selectFilter: '选择一个筛选器...',
          filterName: '筛选器名称',
          filterNameRequired: '筛选器名称是必填项',
          filtersNotAvailable: '筛选器不可用',
          saveFilterTitle: '保存筛选器',
          deleteSavedFilterTitle: '请确认删除筛选器',
          deleteSavedFilterMessage: '您确定要删除筛选器“{{savedFilterName}}”吗？',
          createFilterSuccess: "筛选器 '{{filter}}' 创建成功。您可以在记录筛选器 → 已保存筛选器中找到它",
          createFilterError: '创建筛选器时出错。请稍后重试。',
          deleteFilterSuccess: '筛选器已成功删除',
          deleteFilterError: '删除筛选器时出错。请稍后重试。',
        },
        modalAssignUsers: {
          assignLeakRecord: '分配记录',
          selectAUserToAssign: '选择应分配给所选记录的用户',
          filterByName: '按名称筛选',
          filterByEmployeeName: '按员工姓名筛选',
          unexpectedError: '发生意外错误',
          noUserFound: ' 未找到用户',
          assigningActionForSelectedRecords: '为所选记录分配操作...',
        },
        modalBulkEdit: {
          titleSingleRecord: '批量编辑 {{selectedRecordsCount}} 条记录',
          title: '批量编辑 {{selectedRecordsCount}} 条记录',
          selectAField: '选择一个字段',
          verifyAction: '您即将更新属于其他用户的记录。请通过在下面输入所选记录的数量来确认。',
          matchCountError: '必须与所选记录的数量匹配（{{selectedRecordsCount}}）',
          moreThanOneType: '选中的记录有多种类型。一些字段可能无法批量编辑。',
        },
        tableHead: {
          youAreSelecting: '您正在选择：',
          visibleRecords: '可见记录',
          andAllUnloadedRecords: '以及所有未加载的记录。',
          allRecordsWillBeLoaded: '所有记录将被加载并选择。',
          thisCanTakeAWhile: '如果您有很多记录，这可能需要一段时间。',
          allRecords: '所有记录，包括不可见的记录',
          selectAllRecords: '选择所有记录。',
        },
        tableBody: {
          noRecordsToDisplay: '没有记录可显示。',
        },
        toolBar: {
          assign: '分配',
          onEssentialPlan: '您使用的是基本计划。请联系 support@prosaris.ca',
          bulkEdit: '批量编辑',
          bulkEditSuccess: '成功更新了 {{len}} 条记录',
          bulkEditSuccessPlural: '成功更新了 {{len}} 条记录',
          bulkEditError: '更新记录时出错。请稍后再试',
          exportPDF: '导出PDF',
          needAdminLicense: '你需要管理员许可才能访问此功能。请联系 support@prosaris.ca',
          create: '创建',
          deleteRecordMessage: '您确定要删除选定的记录吗？',
          deleteRecordMessagePlural: '您确定要删除所选记录吗？',
          permissionDenied: '权限被拒绝！',
          notAllowedToDelete: '您选择了不允许删除的他人记录。请将其从选择中移除。',
          generatingPDF: '正在生成PDF...',
          cannotGeneratePDF: '很遗憾，我们现在无法将{{numberSelected}}记录导出为一个PDF文件。请最多选择{{limitRecords}}条记录。',
          cannotExportMessage: '无法导出{{numberSelected}}条记录',
          bulkEditLimitExceeded: '不能编辑超过 {{maxAllowed}} 条记录。请联系 support@prosaris.ca',
        },
        index: {
          cannotLoadLeakRecord: '对不起，我们现在无法加载泄漏记录',
          successfullyAssignedAllRecords: '成功分配了所有记录',
          actionRecordAssigned: '操作记录已分配！',
          actionRecordAssignedPlural: '操作记录已分配！',
          errorWhileAssigningLeak: '分配泄漏时出错。请稍后再试。',
          successfullyDeletedAllRecords: '成功删除了所有记录',
          successfullyDeletedRecord: '成功删除了{{length}}条泄漏记录',
          successfullyDeletedRecordPlural: '成功删除了{{length}}条泄漏记录',
          errorOnRecordsDelete: '删除记录时出错。请稍后再试',
          leakPreview: '预览',
          leakRecord: '记录：',
        },
      },
      tagList: {
        resetTag: '重置标签',
        dataExport: {
          smartLeakTagsFormat: '智能标签格式',
          smartLeakTagsDescription: '包含智能标签页面上显示的默认字段',
          standardLeakRecordFormat: '标准记录格式',
          standardLeakRecordDescription: '包含与泄漏记录相关的所有字段',
        },
        filters: { smartLeakTagFilters: '智能标签过滤器' },
        index: {
          cannotLoadTags: '对不起，我们现在无法加载智能标签',
          updateLeakSuccess: '智能标签重置成功。',
          updateLeakError: '重置智能标签时出错。请稍后再试。',
          bulkEditSuccess: '成功重置 {{len}} 个智能标签',
          bulkEditSuccessPlural: '成功重置 {{len}} 个智能标签',
          bulkEditError: '重置智能标签时出错。请稍后再试。',
          tagsFileName: '智能标签',
          leakFileName: '记录',
        },

        tableBody: {
          title: '请确认重置标签',
          alertDialog: {
            dialogOne: '你正在尝试',
            dialogTwo: '重置标签',
            dialogThree: '从一个泄漏记录中',
            dialogFour: '操作状态：开放',
            dialogFive: '你想继续吗？',
          },
        },
        toolBar: {
          resetTags: '重置标签',
          title: '请确认批量重置标签',
          alertDialog: {
            dialogOne: '您的选择包含',
            dialogTwo: '记录与',
            dialogTwoPlural: '记录与',
            dialogThree: '操作状态：开放',
            dialogFour: '你想继续吗？',
          },
          exportFormatCSV: '选择CSV的导出格式',
          exportFormatPDF: '选择PDF的导出格式',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: '加载分配的操作时出错。请再试一次。',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: '可用图表',
            selectAChart: '选择一个图表...',
            chartFilters: '图表过滤器',
          },
          charts: {
            errorMessages: {
              errorMessageOne: '加载泄漏记录时出错。请再试一次。',
              errorMessageTwo: '请选择一个早于结束日期的日期。',
              errorMessageThree: '请选择一个小于6个月的日期范围。',
              errorMessageFour: '请选择一个晚于开始日期的日期。',
            },
            charts: '图表',
            chartAlreadyExists: '此图表已存在！',
          },
          filters: {
            select: '选择...',
            startDate: '开始日期',
            endDate: '结束日期',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `按泄漏类别划分的流量 (${unit})`,
              LOSS_BY_LEAK: (currency) => `按泄漏类别划分的损失 (${currency})`,
              TAGS_BY_LEAK: '按泄漏类别划分的标签',
              AVG_FLOW_BY_LEAK: (unit) => `按泄漏类别划分的平均流量 (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `按修复时间划分的损失和节省机会 (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `按修复时间划分的流量 (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `按修复时间划分的损失 (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `流量进度 – 已修复 v. 未修复 (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `损失进度 – 已修复 v 未修复 (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `按设备/组件划分的流量 (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `按设备/组件划分的损失 (${currency})`,
              TAGS_BY_EQUIP_COMP: '按设备/组件划分的标签',
            },
            datasetLabel: {
              totalLeakFlow: '发现的总泄漏流量',
              totalLoss: '总损失',
              totalRecords: '发现的总记录',
              avgLeakFlow: '发现的平均泄漏流量',
              totalLossIn: '总损失',
              flowByRepairTime: '按修复时间的流量',
              lossByRepairTime: '按修复时间的损失',
              flowByRepairProgress: '按修复进度发现的流量',
              recordsByCompletion: '按完成情况的记录',
              flowByEquipment: '按设备发现的流量',
              totalLossByEquipment: '按设备的总损失',
              totalRecordsByEquipment: '按设备的总记录',
            },
            labels: {
              low: '低',
              medium: '中',
              high: '高',
              equipment: '设备',
              savings: '节省',
              opportunity: '机会',
              loss: '损失',
              repaired: '已修复',
              unrepaired: '未修复',
              LESS_15_DAYS: '少于15天',
              DAYS_16_TO_30: '15到30天',
              DAYS_31_TO_60: '31到60天',
              DAYS_61_TO_90: '超过60天',
            },
          },
        },
        index: {
          assignedActions: '分配给您的操作',
        },
      },
      export: {
        imageUrlOne: '记录图片URL 1',
        imageUrlTwo: '记录图片URL 2',
        leakTag: '智能标签/工作参考。',
        per: '每 ',
      },
    },
    languages: {
      English: '英语',
      Chinese: '中文',
      Spanish: '西班牙语',
      French: '法语',
      German: '德语',
      Norwegian: '挪威语',
      Italian: '意大利语',
      Turkish: '土耳其的',
      Japanese: '日语',
    },
  },
};
